// components/FormFields/FormikJoditEditor.js
import React from "react";
import { Field, ErrorMessage } from "formik";
import JoditEditor from "jodit-react"; 
function FormikJoditEditor({ name, label, errors,errorClass, rules, ...rest }) {
  const hasError = errors ? errors["description"] :  null;
  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/,
    uploader: {
      url: "https://xdsoft.net/jodit/finder/?action=fileUpload",
    },
    filebrowser: {
      ajax: {
        url: "https://xdsoft.net/jodit/finder/",
      },
      height: 580,
    },
  };

  return (
    <div className={`form-group position-relative ${hasError ? "f-error" : ""}`}>
      {label && (
        <label htmlFor={name} className="justify-content-between">
          {label}
        </label>
      )}
      <Field
        name={name}
        render={({ field, form }) => (
          <JoditEditor
            value={field.value}
            tabIndex={1}
           
            
            onBlur={() => form.setFieldTouched(name, true)}
            onChange={(value) => form.setFieldValue(name, value)}
            // config={editorConfig}
            // config={{
            //   uploader: {
            //     url: '/manage/upload',  //your upload api url
            //     insertImageAsBase64URI: false, 
            //     imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
            //     filesVariableName: function (t) {
            //       return 'files[' + t + ']';
            //     }, //"files",
            //     withCredentials: false,
            //     pathVariableName: 'path',
            //     format: 'json',
            //     method: 'POST',
            //     prepareData: function (formdata) {
            //       return formdata;
            //     },
            //     isSuccess: function (e) {
            //       return e.success;
            //     },
            //     getMessage: function (e) {
            //       return void 0 !== e.data.messages && Array.isArray(e.data.messages)
            //         ? e.data.messages.join('')
            //         : '';
            //     },
            //     process: function (resp) { //success callback transfrom data to defaultHandlerSuccess use.it's up to you.
            //       let files = [];
            //       files.unshift(resp.data);
            //       return {
            //         files: resp.data,
            //         error: resp.msg,
            //         msg: resp.msg,
            //       };
            //     },
               
            //   },
            // }}
            // config={{
            //   uploader: {
            //     url: '/manage/upload',  // Your server upload endpoint
            //     format: 'json',
            //     method: 'POST',
            //     isSuccess: (response) => response.success,
            //     process: (response) => {
            //       // Handle the response from the server
            //       return {
            //         files: [response.data], // Assuming the server returns the uploaded file information
            //       };
            //     },
            //   },
            // }}
            {...rest}

          />
        )}
      />
      <p className="error">
        <ErrorMessage name={name} />
      </p>
    </div>
  );
}

export default FormikJoditEditor;

import axios from "axios";
import {BASE_URL} from "../constants";

export const EMAIL_CAMPAIGN = 'EMAIL_CAMPAIGN';
export const DELETE_EMAIL_CAMPAIGN = 'DELETE_EMAIL_CAMPAIGN';

export const SCHEDULE_EMAIL_CAMPAIGN = 'SCHEDULE_EMAIL_CAMPAIGN';
export const DELETE_SCHEDULE_EMAIL_CAMPAIGN = 'DELETE_SCHEDULE_EMAIL_CAMPAIGN';

export const getEmailCampaignList = (props) => {
    return axios({
        method: 'GET',
        params: props,
        url: `/campaign/list`
    });
};
export function getEmailCampaignById(id){
    return axios({
        method: 'GET',
        // params: payload,
        url: '/campaign/'+id,
    })
}
export const addEmailCampaign = (props) => {
    return axios({
        method: 'POST',
        data: props,
        url: '/campaign'
    });
};
export function editEmailCampaign(payload, id){
    console.log('-----', id);
    return axios({
        method: 'PATCH',
        data: payload,
        url: '/campaign/'+id
    })
}
export function deleteEmailCampaign(id){
    return axios({
        method: 'DELETE',
        url: '/campaign/'+id
    })
}

export function getScheduleCampaignDetail(payload){
    return axios({
        method: 'GET',
        params: payload,
        url: '/automation/getCampaignSchedule',
    })
}

export const getScheduleEmailCampaignList = (props, id) => {
    return axios({
        method: 'GET',
        params: props,
        url: `/listCampaignSchedules/`+id
    });
};

export function deleteScheduleEmailCampaign(id){
    return axios({
        method: 'DELETE',
        url: '/scheduleCampaign/'+id
    })
}

export const scheduleCampaign = (props) => {
    return axios({
        method: 'POST',
        data: props,
        url: '/scheduleCampaign'
    });
};

export function getEmailScheduleCampaignById(id){
    return axios({
        method: 'GET',
        // params: payload,
        url: '/getCampaignSchedule/'+id,
    })
}

export function editScheduleCampaign(payload, id){
    return axios({
        method: 'PATCH',
        data: payload,
        url: '/scheduleCampaign/'+id
    })
}
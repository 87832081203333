import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from 'react-hot-toast';
import {_} from '../../locale';
import Inputfield from "../../components/FormFields/InputField";
import { login } from "../../actions/auth";

function LoginForm() {
  // use Hooks
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();
  const [getPassword, setGetPassword] = useState(true);
  const navigate = useNavigate();

  const onSubmit = async(formValues) => {
    try {
      const resp = await login({...formValues});
      console.log('LoginResponse', resp);
      let result = resp?.data?.responseData ?? null;
      let roles = [];
      let permissions = [];
      let hasAllAccess = 0;
      const fullAccess = await new Promise((resolve, reject) => {
        result?.user?.Roles && result?.user?.Roles.map((obj,index) => {
          permissions = [...permissions, ...obj?.Permissions]
          roles.push(obj?.code);
          if(obj?.code == 'admin'){
            hasAllAccess = 1;
          }else{
            hasAllAccess = 0;
          }
        })
        resolve({permissions, roles, hasAllAccess});
      })

      if (resp.status === 200) {
        localStorage.setItem("jwtToken", result?.user?.token);
        localStorage.setItem("refreshToken", result?.user?.refreshToken);
        localStorage.setItem('permissions', JSON.stringify(fullAccess.permissions));
        localStorage.setItem('roles', JSON.stringify(fullAccess.roles));
        localStorage.setItem('hasAllAccess', fullAccess.hasAllAccess);
        localStorage.setItem('auth', JSON.stringify(result?.user));
        navigate("/dashboard");
      } else {
        if (resp?.data?.responseData?.message)
          toast.error(resp?.data?.responseData?.message);
        else if (resp?.data?.message) toast.error(resp?.data?.message);
        else toast.error("Something went wrong");
      }
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_('NO_INTERNET'));
      }
    }
  };

  const showPassword = () => {
    setGetPassword(!getPassword);
  };

  console.log('++==', getPassword);

  return (
    <div className="p-2">
      <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-3">
          <label htmlFor="username" className="form-label">{_('EMAIL')}</label>
          {
            <Inputfield
              control={control}
              name={"email"}
              placeholder={_('EMAIL')}
              normalize={(e) => e.replace(/^\s+/g, "")}
              type="email"
              inputClassName={"form-control"}
              labelField={"username"}
              rules={{
                required: { value: true, message: _('EMAIL_REQUIRED') },
                pattern: {
                  value:
                    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
                  message: _('EMAIL_VALID'),
                },
              }}
            />
          }
        </div>
        <div className="mb-3">
          <label className="form-label">{_('PASSWORD')}</label>
          <div className="input-group auth-pass-inputgroup">
            <Inputfield
              control={control}
              name={"password"}
              label={_('PASSWORD')}
              placeholder={_('PASSWORD')}
              normalize={(e) => e.replace(/^\s+/g, "")}
              type={getPassword == true ? "password" : "input"}
              inputClassName={"form-control"}
              rules={{
                required: { value: true, message: _('PASSWORD_REQUIRED') },
                minLength: {
                  value: 4,
                  message: _('PASSWORD_VALIDATE'),
                },
              }}
            />
            <button className="btn btn-light " type="button" id="password-addon" onClick={showPassword}><i className="mdi mdi-eye-outline"></i></button>
           </div>
        </div>
        <div className="form-check forget-pass">
          <a href="forgot-password">Forgot Password?</a>
        </div>
        {/* <div className="form-check"></div> */}
          
        <div className="mt-3 d-grid">
          <button
            type="submit"
            disabled={isSubmitting}
            className={`btn btn-primary ${
              isSubmitting ? "btn-loader" : ''
            }`}
          >
            {_('LOGIN')}
          </button>
        </div>
          
      </form>
  </div>
  );
}

export default LoginForm;

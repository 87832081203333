import { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { _ } from "../../locale";
import { toast } from "react-hot-toast";
import Input from "../../components/FormFields/input";
import { Formik, Form, FieldArray, Field, ErrorMessage } from "formik";
import { validationSchema } from "./schema";
import { addEmailRecord } from "../../actions/emailRecord";

function AddEmailRecord() {
  const { id } = useParams();
  // use hooks
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm();
  const navigate = useNavigate();

  // create initial values
  let initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    metadata: [
      {
        metaKey: "",
        metaValue: ""
      },
    ],
  };

  // on form submissions
  const onSubmit = async (formData) => {
    try {
      let payload = {
        emailListId: id,
        firstName: formData?.firstName,
        lastName: formData?.lastName,
        email: formData?.email,
        metadata: formData?.metadata?.map((option, index) => {
          return {
            metaKey: option?.metaKey,
            metaValue: option?.metaValue,
          };
        }),
      };

      let dataArray = payload.metadata;
      let result = {};
      let listUrl = "/dashboard/email-list/"+id+"/record";

      // if(arr.length){
      //   result = arr.reduce((acc, obj) => {
      //     Object.keys(obj).forEach(key => {
      //         acc[key] = obj[key];
      //     });
      //     return acc;
      //   }, {});
      // }

      if(dataArray.length){
        dataArray.forEach(item => {
          result[item.metaKey] = item.metaValue;
        });
      }
      payload = {...payload, metadata: result};

      await addEmailRecord({ ...payload});
      navigate(listUrl);
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_('NO_INTERNET'));
      }
    }
  }

  
  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 font-size-18">
                  {_("CREATE_EMAIL_RECORD")} 
                </h4>
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item">
                    <Link to={`/dashboard/email-list`}>{_("EMAIL_LIST")}</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={`/dashboard/email-list/${id}/record`}>{_("EMAIL_RECORD")}</Link>
                  </li>
                  <li className="breadcrumb-item active">{_("ADD")}</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">{_("EMAIL_RECORD")}</h4>
                <p className="card-title-desc">{_("EMAIL_RECORD_INFO")}</p>

                <Formik
                  enableReinitialize={true}
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {(formikProps) => {
                    return (
                      <Form>
                        <div className="row">
                          <div className="col-md-6 mb-3">
                            <Input
                              name="firstName"
                              label={_("FIRST_NAME")}
                              placeholder={_("FIRST_NAME")}
                              type="text"
                              className="form-control"
                              errors={formikProps?.errors}
                            />
                          </div>
                          <div className="mb-3 col-md-6">
                            <Input
                              name="lastName"
                              label={_("LAST_NAME")}
                              placeholder={_("LAST_NAME")}
                              type="text"
                              className="form-control"
                              errors={formikProps?.errors}
                            />
                          </div>
                        </div>
                        
                        <div className="row">
                          <div className="mb-3">
                            <Input
                              name="email"
                              label={_("EMAIL")}
                              placeholder={_("EMAIL")}
                              type="email"
                              className="form-control"
                              errors={formikProps?.errors}
                            />
                          </div>
                        </div>

                        <div className="col-md-12 mb-3">
                          <FieldArray name="metadata">
                            {({ insert, remove, push }) => (
                              <div className="que-option-blk">
                                {formikProps.values.metadata.length > 0 &&
                                  formikProps.values.metadata.map((meta, index) => (
                                    <div className="row" key={index}>
                                      <div className="mb-3 col-md-5 e-colmn">
                                        <Input
                                          name={`metadata.${index}.metaKey`}
                                          label={_("META_KEY")}
                                          placeholder={_("META_KEY")}
                                          type="text"
                                          className="form-control"
                                          errors={formikProps?.errors}
                                        />
                                      </div>
                                      <div className="mb-3 col-md-5 e-colmn">
                                        <Input
                                          name={`metadata.${index}.metaValue`}
                                          label={_("META_VALUE")}
                                          placeholder={_("META_VALUE")}
                                          type="text"
                                          className="form-control"
                                          errors={formikProps?.errors}
                                        />
                                      </div>
                                      <div className="align-items-end d-flex col-md-1 add-delete-action">
                                        <button
                                          type="button"
                                           className="action-btn delete"
                                          onClick={() => remove(index)}
                                        >
                                         <img src="/assets/images/-.svg" alt="remove"/>
                                        </button>

                                        {
                                         index === formikProps.values.metadata?.length - 1 && (
                                          <button
                                            type="button"
                                            className="action-btn add"
                                            onClick={() => push({ metaKey: '', metaValue: '' })}
                                          >
                                            <img src="/assets/images/+.svg" alt="add" /> 
                                          </button>
                                          )}
                                      </div>
                                    </div>
                                  ))}
                                
                              </div>
                            )}
                          </FieldArray>
                        </div>

                        <div className="d-flex flex-wrap gap-2 mt-3 align-items-center">
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className={`btn btn-primary waves-effect waves-light ${
                              isSubmitting && "btn-loader"
                            }`}
                          >
                            {_("SAVE")}
                          </button>
                          <div>
                          <Link
                            to={`/dashboard/email-list/${id}/record`}
                            className="btn btn-secondary  waves-effect"
                          >
                            {_("CANCEL")}
                          </Link>
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddEmailRecord;

import { useCallback, useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-hot-toast";
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';
import { PAGE_LIMIT } from "../../constants";
import {_} from "../../locale";
import ScheduleAutomationSlide from './ScheduleAutomationSlide';
import ScheduleSkelton from './ScheduleSkelton';
import { getScheduleAutomationList, SCHEDULE_EMAIL_AUTOMATION } from "../../actions/emailAutomation";

function ManageScheduleAutomation() {
  // local variables
  const dispatch = useDispatch();
  const list = useSelector(state => state.emailAutomation);
  const [activePage, setActivePage] = useState(1);
  const [showLoader, setShowLoader] = useState(false);
  const { id } = useParams();

  const fetchData = useCallback( async (page) => {
    setShowLoader(true);
    try {
      const resp = await getScheduleAutomationList({ 'page': page}, id);
      dispatch({ type: SCHEDULE_EMAIL_AUTOMATION, payload: resp?.data?.responseData });
      setShowLoader(false);
    } catch ({response, request}) {
      // console.log(error, '====');
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_('NO_INTERNET'));
      }
      setShowLoader(false);
    }
  },[]);

  const handlePageChange = (page) => {
    setActivePage(page);
  };

  

  useEffect(() => {
    fetchData(activePage);
  }, [fetchData, activePage]);

  let limit = list?.scheduleEmailAutomationListing?.perPage ?? PAGE_LIMIT;

  console.log('list', list);
  console.log("limit",limit)
  return (
    <div className="page-content">
      <div className="container-fluid">
        
        <div className="row">
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
                <li className="breadcrumb-item"><Link to={`/dashboard/email-automation`}>{_('EMAIL_AUTOMATION')}</Link></li>
                <li className="breadcrumb-item active">{_('SCHEDULE_EMAIL_AUTOMATION')}</li>
            </ol>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 font-size-18">{_('SCHEDULE_EMAIL_AUTOMATION')}</h4>
              <div className="flex-shrink-0 gap-2">
                <Link to={`/dashboard/email-automation/${id}/schedule/add`} className="btn btn-primary">{_('ADD')}</Link>
            </div>
            </div>
          </div>
        </div>                        

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table align-middle table-nowrap table-hover">
                    <thead className="table-light">
                      <tr>
                        <th scope="col" style={{width: "70px"}}>#</th>
                        <th scope="col">{_('FROM_EMAIL')}</th>
                        <th scope="col">{_('FROM_NAME')}</th>
                        <th scope="col">{_('CALCULATE_FROM')}</th>
                        <th scope="col">{_('TOTAL_SENT')}</th>
                        <th scope="col">{_('DELIVERED')}</th>
                        <th scope="col">{_('WORKFLOW')}</th>
                        <th scope="col">{_('CREATED')}</th>
                        <th scope="col">{_('STATUS')}</th>
                        <th scope="col">{_('ACTION')}</th>
                      </tr>
                    </thead>

                    <tbody>
                      {
                        showLoader == true
                        ?
                        <ScheduleSkelton count={5} />
                        :
                        (
                         list?.scheduleEmailAutomationListing?.data?.length > 0
                          ?
                          list && list?.scheduleEmailAutomationListing?.data?.length > 0 && list?.scheduleEmailAutomationListing?.data?.map((obj, index) => (
                            <ScheduleAutomationSlide slideData={obj} key={obj.id} id={id} index={index} srno={(activePage - 1) * limit} emailListId={id} />
                          ))
                          :
                          <tr>
                            <td colspan="8" className="empty-record-msg">
                              <h5 className="text-truncate mb-4 mb-lg-5">No Record exist. <Link to={`/dashboard/email-campaign/${id}/schedule/add`}>Click here</Link> to schedule new email campaign</h5>
                            </td>
                          </tr>
                        )
                      } 
                    </tbody>
                    
                  </table>
                </div>
              </div>
            </div>
            <ResponsivePagination
              current={activePage}
              total={list?.totalPages ?? 1}
              onPageChange={handlePageChange}
            /> 
          </div>
        </div>
      </div> 
    </div>
  );
}
export default ManageScheduleAutomation;
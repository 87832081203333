import axios from "axios";

export function login(payload){
    return axios({
        method: 'POST',
        data: payload,
        url: '/login'
    })
}

export function uploadFile(props) {
    return axios({
      method: 'POST',
      data: props,
      url: `/attachment/upload`
    })
};

export function removeFile(uniqueName) {
    return axios({
      method: 'DELETE',
      // params: props,
      url: `attachment/`+uniqueName
    })
};

export function forgotPassword(payload){
  return axios({
      method: 'POST',
      data: payload,
      url: '/admin-api/user/forgot-password/'
  })
}
export function resetPassword(token,payload){
  return axios({
      method: 'POST',
      data: payload,
      url: `admin-api/user/forgot-password/reset-password/${token}`
  })
}
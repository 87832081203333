import axios from "axios";
import {BASE_URL} from "../constants";

export const getKeys = (props) => {
    return axios({
        method: 'GET',
        params: props,
        url: `/keys`
    });
};
export const generateKey = (props) => {
    return axios({
        method: 'POST',
        data: props,
        url: '/generate'
    });
};


import { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { _ } from "../../locale";
import { toast } from "react-hot-toast";
import Input from "../../components/FormFields/input";
import { Formik, Form, FieldArray, Field, ErrorMessage } from "formik";
import { useDispatch } from "react-redux";
// import { validationSchema } from "./schema";
import FormikJoditEditor from "../../components/FormFields/JodiEditor";
import { editEmailTemplate, getEmailTemplateById } from "../../actions/emailTemplate";
import FileUpload from "../../components/FormFields/FileUpload";
// import SingleCheckboxInput from "../../components/FormikFields/SingalCheckBoxField";

function EditEmailTemplate() {
  const [template, setTemplate] = useState([]);
  const [templateImages, setTemplateImages] = useState(null);
  const [templateImageIds, setTemplateImageIds] = useState(null);
  const [checked, setChecked] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // let id = new URLSearchParams(window.location.search).get("id");
  let { id } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = () => {
    setChecked(!checked);
  };

  // create initial values
  let initialValues = {
    code: "",
    title: "",
    subject: "",
    message: "",
    replacements: "",
    isDefault: false,
    attachments: null,
  };

  if (id) {
    initialValues = {
      code: template?.code ?? "",
      title: template?.title ?? "",
      subject: template?.subject ?? "",
      isDefault: template?.isDefault ?? false,
      message: template?.message ?? "",
      replacements: template?.replacements ?? "",
      attachments: template?.Attachments
    };
  }


  // on form submissions
  const onSubmit = async (values) => {
    console.log(checked, '=======', values);
    values = {...values, isDefault: checked}

    console.log(id,'77777', values);

    try {
      await editEmailTemplate(values, id);
      navigate('/dashboard/email-template');
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_('NO_INTERNET'));
      }
    }
  }

  const fetchRecords = async (id) => {
    try {
      const records = await getEmailTemplateById(id);
      setTemplate(records?.data?.responseData ?? {});
      let result = records?.data?.responseData ?? {};
      setTemplateImages(result?.Attachments);
      let img = [];
      let imgPath = [];

      result?.Attachments?.length > 0 && result?.Attachments.map((obj, index) => {
        // imgPath.push({'id': obj?.id, 'filePath': obj?.filePath})
        img.push(obj.id);
      });
      setTemplateImageIds(img);
      // setTemplateImages(imgPath);

    } catch (error) {}
  };

 useEffect(() => {
    fetchRecords(id);
  }, [])


 console.log(templateImageIds,'434344344334', templateImages);

  
  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 font-size-18">
                  {_("EDIT_EMAIL_TEMPLATE")} 
                </h4>
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item">
                    <Link to={`/dashboard/email-template`}>{_("EMAIL_TEMPLATE")}</Link>
                  </li>
                  <li className="breadcrumb-item active">{_("EDIT")}</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">{_("EMAIL_TEMPLATE")}</h4>
                <p className="card-title-desc">{_("EMAIL_TEMPLATE_INFO")}</p>

                <Formik
                  enableReinitialize={true}
                  initialValues={initialValues}
                  // validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {(formikProps) => {
                    console.log(formikProps,"formikProps")
                    return (
                      <Form>
                        <div className="row">
                          <div className="col-md-6 plr-0">
                            <Input
                              name="code"
                              label={_("CODE")}
                              placeholder={_("CODE")}
                              type="text"
                              className="form-control"
                              errors={formikProps?.errors}
                            />
                          </div>
                          <div className="col-md-6 plr-0">
                            <Input
                              name="title"
                              label={_("TITLE")}
                              placeholder={_("TITLE")}
                              type="text"
                              className="form-control"
                              errors={formikProps?.errors}
                            />
                          </div>
                        </div>
                        
                        <div className="row">
                          <div className="col-md-6 plr-0">
                            <Input
                              name="subject"
                              label={_("SUBJECT")}
                              placeholder={_("SUBJECT")}
                              type="text"
                              className="form-control"
                              errors={formikProps?.errors}
                            />
                          </div>
                          <div className="col-md-6 has-checkbox plr-0">
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                name="isDefault"
                                checked={checked}
                                onChange={handleChange}
                              />
                              <span className="form-check-label"> {_("IS_DEFAULT")}</span>
                            </div>
                          </div>

                        </div>

                        <div className="row">
                          <div className="plr-0">
                            <Input
                              name="replacements"
                              label={_("REPLACEMENTS")}
                              placeholder={_("REPLACEMENTS")}
                              type="text"
                              className="form-control"
                              errors={formikProps?.errors}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="plr-0">
                            <FormikJoditEditor
                              name="message"
                              label={_("MESSAGE")}
                              errorClass="error-msg"
                              errors={formikProps?.errors}
                            />
                          </div>
                        </div>

                        
                        <div className="row">
                          <div className="mb-3 plr-0">
                            <FileUpload 
                              label={_("UPLOAD_FILES")}
                              name="attachments"
                              isMultiple={true}
                              preUploadedFiles={templateImages ? templateImages : []}
                              preUploadedFileIds={templateImageIds ? templateImageIds : null}
                              errors={formikProps?.errors}
                            />
                          </div>
                        </div>


                        <div className="d-flex flex-wrap gap-2 mt-3 align-items-center">
                          <button
                            type="submit"
                            // disabled={isSubmitting}
                            className={`btn btn-primary waves-effect waves-light ${
                              isSubmitting && "btn-loader"
                            }`}
                          >
                            {_("SAVE")}
                          </button>
                          <div>
                          <Link
                            to={`/dashboard/email-template`}
                            className="btn btn-secondary  waves-effect"
                          >
                            {_("CANCEL")}
                          </Link>
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditEmailTemplate;

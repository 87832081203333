import axios from "axios";
import {BASE_URL} from "../constants";

export const EMAIL_AUTOMATION = 'EMAIL_AUTOMATION';
export const DELETE_EMAIL_AUTOMATION = 'DELETE_EMAIL_AUTOMATION';
export const EMAIL_AUTOMATION_STATUS = 'EMAIL_AUTOMATION_STATUS';
export const SCHEDULE_EMAIL_AUTOMATION = 'SCHEDULE_EMAIL_AUTOMATION';
export const DELETE_AUTOMATION_SCHEDULE = 'DELETE_AUTOMATION_SCHEDULE';

export const getEmailAutomationList = (props) => {
    return axios({
        method: 'GET',
        params: props,
        url: `/automation/list`
    });
};
export function getEmailAutomationById(id){
    return axios({
        method: 'GET',
        // params: payload,
        url: '/automation/'+id,
    })
}
export const addEmailAutomation = (props) => {
    return axios({
        method: 'POST',
        data: props,
        url: '/automation'
    });
};
export function editEmailAutomation(payload, id){
    console.log('-----', id);
    return axios({
        method: 'PATCH',
        data: payload,
        url: '/automation/'+id
    })
}
export function deleteEmailAutomation(id){
    return axios({
        method: 'DELETE',
        url: '/automation/'+id
    })
}

export function updateEmailAutomationStatus(payload, id){
    // console.log('-----', id);
    return axios({
        method: 'PATCH',
        data: payload,
        url: '/automation/status/'+id
    })
}

export function getScheduleAutomationDetail(payload){
    return axios({
        method: 'GET',
        params: payload,
        url: '/automation/getAutomationSchedule',
    })
}
export const scheduleAutomation = (props) => {
    return axios({
        method: 'POST',
        data: props,
        url: '/scheduleAutomation'
    });
};

export const getScheduleAutomationList = (props, id) => {
    return axios({
        method: 'GET',
        params: props,
        url: `/scheduleAutomation/list/`+id
    });
};

export const addEmailScheduleAutomation = (props, id) => {
    return axios({
        method: 'POST',
        data: props,
        url: '/scheduleAutomation/'+id
    });
};

export const getAutomationScheduleBDetail = (automationId, id) => {
    return axios({
        method: 'GET',
        // params: props,
        url: `/scheduleAutomation/${automationId}/${id}`
    });
};

export function editAutomationSchedule(payload, automationId, id){
    // console.log('-----', id);
    return axios({
        method: 'PATCH',
        data: payload,
        url: `/scheduleAutomation/${automationId}/${id}`
    })
}; 

export function deleteAutomationSchedule(automationId, id){
    return axios({
        method: 'DELETE',
        url: `/scheduleAutomation/${automationId}/${id}`
    })
};

export const getAutomationTree = (props) => {
    return axios({
        method: 'GET',
        params: props,
        url: `/scheduleAutomation/getAutomationTree`
    });
};

export const addEmailScheduleWorkFlow = (props, scheduleId) => {
    return axios({
        method: 'POST',
        data: props,
        url: '/scheduleAutomation/interval/'+scheduleId
    });
};

export const editEmailScheduleWorkFlow = (props, id, scheduleId) => {
    return axios({
        method: 'PATCH',
        data: props,
        url: '/scheduleAutomation/interval/'+scheduleId+"/"+id
    });
};
export function deleteScheduleInternval(scheduleId, id){
    return axios({
        method: 'DELETE',
        url: `/scheduleAutomation/interval/${scheduleId}/${id}`
    })
};



import { combineReducers } from "redux";
import emailList from './emailList';
import emailRecord from './emailRecord';
import emailTemplate from "./emailTemplate";
import emailAutomation from "./emailAutomation";
import emailCampaign from "./emailCampaign";
import dashboard from "./dashboard";
import email from './email';
import user from './user';

export default combineReducers({
	emailList,
	emailRecord,
	emailTemplate,
	emailAutomation,
	emailCampaign,
	dashboard,
	email,
	user
});
import { Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import "./App.css";
import Login from "./pages/auth/Login";
import Layout from "./pages/layout/Layout";
import ResetPassword from "./components/common/ResetPassword";
import ForgotPassword from "./components/common/ForgotPassword";
// import PageNotFound from "./pages/PageNotFound/PageNotFound";
function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="login" element={<Login />} />
        <Route path="forgot-password" element={<ForgotPassword/>}/>
        <Route path="admin/reset-password/:id" element={<ResetPassword/>}/>
        <Route path="dashboard/*" element={<Layout />} />
        {/**
        <Route path="*" element={<PageNotFound />} />
        **/}
      </Routes>

      <Toaster position="top-right" />
    </>
  );
}

export default App;
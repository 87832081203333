import { useController } from "react-hook-form";
import { errorType } from "../../constants";

function InputTextAreaField({
  control,
  name,
  defaultValue,
  rules,
  parentDivClass,
  label,
  fieldClass,
  placeholder,
  readOnly,
  type,
  onChange,
  normalize
}) {
  // use form hooks
  const {
    field,
    fieldState: { error },
  } = useController({ control, name, rules, defaultValue });

 // handling input change
 const onInputChange = (e) => {
    let value = e.target.value;
    if (onChange) onChange(normalize(value.replace(/  +/g, " "))); // send value to provided func

    field.onChange(normalize(value.replace(/  +/g, " "))); // send value to hook form 
  };

  // console.log('4443444', fieldClass);

  return (
    <>
      <textarea
        {...field}
        className={` ${fieldClass ? fieldClass : "textfield"} ${
          error !== undefined ? " error " : ""
        }`}
        placeholder={placeholder ? placeholder : label}
        onChange={onInputChange}
        readOnly={readOnly}
        type={type}
      />
      {errorType?.map((type) => {
        if (error?.type === type && error?.message !== "") {
          return <span className="error">{error?.message}</span>;
        }
      })}
    </>
  );
}

/**
 * @property defaults
 */
 InputTextAreaField.defaultProps = {
  defaultValue: "",
  rules: {},
  onChange: (value) => value,
  normalize: (value) => value,
};

export default InputTextAreaField;

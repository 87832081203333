import { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { _ } from "../../locale";
import { Formik, Form, FieldArray, Field, ErrorMessage } from "formik";
// import { validationSchema } from "./schema";
import { toast } from "react-hot-toast";
import { importEmailRecord } from "../../actions/emailRecord";
import CsvFileUpload from "../../components/FormFields/CsvFileUpload";

function ImportEmailRecordCsv() {
  const [imageLoad, setImageLoad] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();

  const { id } = useParams();

  let initialValues = {
    csvRecord: null,
  };

  // on form submissions
  const onSubmit = async (values) => {
    console.log('=======', values);
    setShowLoader(true);
    try {
      await importEmailRecord({'fileId': values?.csvRecord}, id);
      toast.success('Record has been imported successfully');
      setShowLoader(false);
      // navigate('/dashboard/email-template');
    } catch ({ response, request }) {
      setShowLoader(false);
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_('NO_INTERNET'));
      }
    }
  }

  
  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 font-size-18">
                  {_("UPLOAD_EMAIL_RECORD_CSV")} 
                </h4>
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item">
                    <Link to={`/dashboard/email-list/${id}/record`}>{_("EMAIL_RECORD")}</Link>
                  </li>
                  <li className="breadcrumb-item active">{_("UPLOAD")}</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">{_("EMAIL_RECORD")}</h4>
                <p className="card-title-desc">{_("EMAIL_RECORD_INFO")}</p>

                <Formik
                  enableReinitialize={true}
                  initialValues={initialValues}
                  // validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {(formikProps) => {
                    console.log(formikProps,"formikProps")
                    return (
                      <Form>
                        <div className="row">
                          <div className="mb-3">
                            <CsvFileUpload 
                              label="Upload CSV"
                              name="csvRecord"
                              isMultiple={false}
                              preUploadedFiles={[]}
                              preUploadedFileIds={null}
                              errors={formikProps?.errors}
                            />
                          </div>
                        </div>
                        <div className="d-flex flex-wrap gap-2 mt-3 align-items-center">
                          <button
                            type="submit"
                            // disabled={isSubmitting}
                            className={`btn btn-primary waves-effect waves-light ${
                              isSubmitting && "btn-loader"
                            }`}
                          >
                            {_("SAVE")}
                          </button>
                          <div>
                          <Link
                            to={`/dashboard/email-template`}
                            className="btn btn-secondary  waves-effect"
                          >
                            {_("CANCEL")}
                          </Link>
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
              {
                showLoader == true && 
                  <div className="automation-loader">
                    <img src="/assets/images/loader.gif" />
                  </div>
              }

            </div>





          </div>
        </div>
      </div>
    </div>
  );
}

export default ImportEmailRecordCsv;

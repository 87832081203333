import axios from "axios";
import {BASE_URL} from "../constants";

export const EMAIL_RECORD = 'EMAIL_RECORD';
export const DELETE_EMAIL_RECORD = 'DELETE_EMAIL_RECORD';
export const EMAIL_RECORD_STATUS = 'EMAIL_RECORD_STATUS';

export const getEmailRecord = (props, id) => {
    return axios({
        method: 'GET',
        params: props,
        url: `/emailRecord/list/`+id
    });
};
export function getEmailRecordById(listId, id){
    return axios({
        method: 'GET',
        // params: payload,
        url: '/emailRecord/'+listId+'/'+id,
    })
}
export const addEmailRecord = (props) => {
    return axios({
        method: 'POST',
        data: props,
        url: '/emailRecord'
    });
};
export function editEmailRecord(payload, listId, id){
    console.log('-----', id);
    return axios({
        method: 'PATCH',
        data: payload,
        url: '/emailRecord/'+listId+'/'+id
    })
}
export function deleteEmailRecord(listId, id){
    return axios({
        method: 'DELETE',
        url: '/emailRecord/'+listId+'/'+id
    })
}

export function updateEmailRecordStatus(payload, listId, id){
    // console.log('-----', id);
    return axios({
        method: 'PATCH',
        data: payload,
        url: '/emailRecord/status/'+listId+'/'+id
    })
}

export const importEmailRecord = (props, id) => {
    return axios({
        method: 'POST',
        data: props,
        url: '/emailRecord/import/'+id
    });
};

export const exportEmailRecord = (payload, id) => {
    return axios({
        method: 'GET',
        params: payload,
        url: '/emailRecord/export/'+id
    });
};

import { Link } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import CustomConfirmAlert from '../../components/common/CustomConfirmAlert';
import { deleteEmailList, updateEmailListStatus, DELETE_EMAIL_LIST, EMAIL_LIST_STATUS } from "../../actions/emailList";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import {_} from "../../locale";
import { useDispatch } from "react-redux";
import { Tooltip as ReactTooltip } from "react-tooltip";
import EmailListGraph from "./EmailListGraph";
// import "react-tooltip/dist/react-tooltip.css";
import moment from 'moment';

function ManageEmailListSlide({slideData, index, srno}) {
  //   local variables
  const [status, setStatus] = useState(slideData?.status);
  const [delIcon, setDelIcon] = useState("alert-delete-icon.png");
  const dispatch = useDispatch();

  const deleteRecord = () => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <CustomConfirmAlert
          onClose={onClose}
          confirmationMsg={_('CONFIRM_DELETE')}
          actionIcon = {"/assets/images/alert-delete-icon.png"}
          onConfirm={async() => {
            // Perform delete operation here
            try {
              await deleteEmailList(slideData?.id);
              dispatch({ type: DELETE_EMAIL_LIST, id: slideData?.id });
              toast.info(_('CONFIRM_DELETE_SUCCESS'));
            } catch (error) {
              console.log(error);
            }
          }}
          confirmationIcon="alert-delete-icon.png"
        />
      ),
    });
  };

   const updateStatus = () => {
    let status = (slideData.status == '1' ? 0 : 1);
    let chkStatus = (slideData.status == '1' ? 0 : 1);
    confirmAlert({
      customUI: ({ onClose }) => (
        <CustomConfirmAlert
          onClose={onClose}
          confirmationMsg={slideData.status == '1' ? _('CONFIRM_DEACTIVATE') : _('CONFIRM_ACTIVATE')}
          actionIcon = {"/assets/images/custom_alert_warning.png"}
          onConfirm={async() => {
            // Perform delete operation here
            try {
              const response = await updateEmailListStatus({status} ,slideData?.id);
              dispatch({ type: EMAIL_LIST_STATUS, payload: response?.data?.responseData });
            } catch (error) {
              console.log(error);
            }
          }}
        />
      ),
    });
  };

  useEffect(() => {
    setStatus(slideData.status)
  }, [slideData.status])
  
  return (
    <>
    <tr className="data-graph-parent">
      <td>{parseInt(srno + parseInt(index + 1))}</td>
      <td>{slideData?.id}</td>
      <td>
        <p className="font-size-14 mb-1 tooltip-title" id={"email-list-"+slideData?.id}>{slideData?.title ?? null}</p>
        <ReactTooltip anchorId={"email-list-"+slideData?.id} place="top" variant="info" content={<p dangerouslySetInnerHTML={{__html: slideData?.description}} />} />

        

      </td>
      <td><p className="font-size-14 mb-1">{slideData?.totalRecords ?? 0}</p></td>
      <td><p className="font-size-14 mb-1">{slideData?.totalSubscribed ?? 0}</p></td>
      <td><p className="font-size-14 mb-1">{slideData?.totalUnsubscribed ?? 0}</p></td>
      <td>
        <h5 class="font-size-14 mb-1"><a href="javascript: void(0);" class="text-dark">{moment(slideData?.createdAt).format('lll') ?? null}</a></h5>
        <p class="text-muted mb-0">{_('BY')} {slideData?.author?.name ?? null}</p>
      </td>
      <td>
        <h5 class="font-size-14 mb-1"><a href="javascript: void(0);" class="text-dark">{slideData?.updatedAt ? moment(slideData?.updatedAt).format('lll') : moment(slideData?.createdAt).format('ll')}</a></h5>
        <p class="text-muted mb-0">{_('BY')} {slideData?.updatedBy?.name ? slideData?.updatedBy?.name : slideData?.author?.name }</p>
      </td>
      <td>
        <a href="javascript:void(0)" onClick={updateStatus}>
        {
          status
          ?
          <span className="badge bg-success">{_('ACTIVE')}</span>
          :
          <span className="badge bg-danger">{_('NOT_ACTIVE')}</span>  
        }
        </a>
      </td>
      <td>
        <ul className="list-unstyled hstack gap-1 mb-0">
          <li>
              <Link to={`/dashboard/email-list/${slideData.id}/edit`} className="btn btn-sm btn-soft-info" title="Edit"><i className="mdi mdi-pencil-outline"></i></Link>
          </li>
          <li>
            <a href="javascript:void(0)" onClick={deleteRecord} className="btn btn-sm btn-soft-danger" title="Delete"><i className="mdi mdi-delete-outline"></i></a>
          </li>

          <li>
            <Link to={`/dashboard/email-list/${slideData?.id}/record`} className="btn btn-sm btn-soft-info" title="Email record"><img src="/assets/images/email-record.png" width="24" height="24" /></Link>
          </li>
          <li>
              <Link to={`/dashboard/email-list/${slideData.id}/segment`} className="btn btn-sm btn-soft-danger" title="Email segment"><img src="/assets/images/email-segment.png" width="24" height="24" /></Link>
          </li>
        </ul>
      </td>
  </tr>

  <tr className="data-graph">
      <td colspan="10" className="data-graph-child">
        <EmailListGraph slideData={slideData} />
      </td>
    </tr>
  </>


  );
}

export default ManageEmailListSlide;

import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {_} from "../../locale";
import { toast } from "react-hot-toast";
import EmailStatsPieChart from './EmailStatsPieChart';
import { getEmailStats, EMAIL_LIST_STATS } from "../../actions/dashboard";

function EmailStats() {
  // local variables
  const dispatch = useDispatch();
  const list = useSelector(state => state.dashboard);
  const [activePage, setActivePage] = useState(1);
  const [showLoader, setShowLoader] = useState(false);

  const fetchData = useCallback( async (page) => {
    setShowLoader(true);
    try {
      const resp = await getEmailStats();
      dispatch({ type: EMAIL_LIST_STATS, payload: resp?.data?.responseData });
      setShowLoader(false);
    } catch ({response, request}) {
      // console.log(error, '====');
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_('NO_INTERNET'));
      }
      setShowLoader(false);
    }
  },[]);

  useEffect(() => {
    fetchData();
  }, []);

  console.log('list', list);
  return (
    <div className="row">
      <div className="col-lg-6">
        <div className="card email-tab-stats">
          <div className="card-body">
            <div className="table-responsive">
              <table className="table align-middle table-nowrap table-hover">
                <thead className="table-light">
                  <tr>
                    <th scope="col">{_('TITLE')}</th>
                    <th scope="col">{_('STATS')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{_('TOTAL_EMAIL_LISTS')}</td>
                    <td>{list?.emailListStats?.totalEmailLists ?? 0}</td>
                  </tr>
                  <tr>
                    <td>{_('TOTAL_EMAIL_RECORDS')}</td>
                    <td>{list?.emailListStats?.totalEmailRecords ?? 0}</td>
                  </tr>
                  <tr>
                    <td>{_('TOTAL_SUBSCRIBERS')}</td>
                    <td>{list?.emailListStats?.totalSubscribers ?? 0}</td>
                  </tr>
                  <tr>
                    <td>{_('TOTAL_UNUBSCRIBERS')}</td>
                    <td>{list?.emailListStats?.totalUnubscribers ?? 0}</td>
                  </tr>
                  
                </tbody>
                
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <EmailStatsPieChart detail={list} />
      </div>

    </div>
  );
}
export default EmailStats;

import { Link } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import CustomConfirmAlert from '../../components/common/CustomConfirmAlert';
import { deleteAutomationSchedule, DELETE_AUTOMATION_SCHEDULE } from "../../actions/emailAutomation";
import { useEffect, useState } from "react";
import {_} from "../../locale";
import { toast } from "react-hot-toast";
import ProgressBar from 'react-bootstrap/ProgressBar';
import EmailAutomationScheduleGraph from "./EmailAutomationScheduleGraph";
import {INTERVAL_TYPE, CALCULATE_FROM} from "../../constants";
import { useDispatch } from "react-redux";
import moment from 'moment';

function ScheduleAutomationSlide({slideData, id, index, srno, emailListId}) {

  //   local variables
  const [status, setStatus] = useState(slideData?.status);
  const dispatch = useDispatch();

  const deleteRecord = () => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <CustomConfirmAlert
          onClose={onClose}
          confirmationMsg={_('CONFIRM_DELETE')}
          actionIcon = {"/assets/images/alert-delete-icon.png"}
          onConfirm={async() => {
            // Perform delete operation here
            try {
              await deleteAutomationSchedule(id, slideData?.id);
              dispatch({ type: DELETE_AUTOMATION_SCHEDULE, id: slideData?.id });
              toast.info(_('CONFIRM_DELETE_SUCCESS'));
            } catch (error) {
              console.log(error);
            }
          }}
          confirmationIcon="alert-delete-icon.png"
        />
      ),
    });
  };

  const updateStatus = () => {
    // let status = (slideData.status == '1' ? 0 : 1);
    // let chkStatus = (slideData.status == '1' ? 0 : 1);
    // confirmAlert({
    //   title: 'Confirm',
    //   message: slideData.status == '1' ? _('CONFIRM_DEACTIVATE') : _('CONFIRM_ACTIVATE'),
    //   buttons: [
    //     {
    //       label: _('YES'),
    //       onClick: async () => {
    //         try {
    //           const response = await updateEmailRecordStatus({status} ,slideData?.id);
    //           dispatch({ type: EMAIL_RECORD_STATUS, payload: response?.data?.responseData });
    //         } catch (error) {
    //           console.log(error);
    //         }
    //       }
    //     },
    //     {
    //       label: _('NO')
    //     }
    //   ]
    // });
  };

  useEffect(() => {
    setStatus(slideData.status)
  }, [slideData.status])

  console.log(slideData, "SlideQuestion")

  let totalEmailRecords = 0;

  slideData?.CampaignScheduleEmailLists?.length > 0 && slideData?.CampaignScheduleEmailLists?.map((obj, index) => {
    totalEmailRecords += obj?.EmailList?.totalRecords ?? 0
  })


  return (
    <>
      <tr className="data-graph-parent">
        <td>{parseInt(srno + parseInt(index + 1))}</td>
        <td>
          <p className="font-size-14 mb-1">{slideData?.fromEmail ?? null}</p>
        </td>
        <td><p className="font-size-14 mb-1">{slideData?.fromName ?? null}</p></td>
        

        <td><p className="font-size-14 mb-1">{CALCULATE_FROM[slideData?.calculateFrom -1].label}</p></td>
        <td><p className="font-size-14 mb-1">{slideData?.totalSent}</p></td>
        <td><p className="font-size-14 mb-1">{slideData?.delivered}</p></td>

        <td>
          <Link to={`/dashboard/email-automation/${id}/schedule/${slideData.id}/work-flow`} className="btn btn-sm btn-soft-info" title="Edit">{_('WORKFLOW')}</Link>
        </td>

        <td>
          <h5 class="font-size-14 mb-1"><a href="javascript: void(0);" class="text-dark">{moment(slideData?.createdAt).format('lll') ?? null}</a></h5>
        </td>
        <td>
          <a href="javascript:void(0)" onClick={updateStatus}>
          {
            status
            ?
            <span className="badge bg-success">{_('ACTIVE')}</span>
            :
            <span className="badge bg-danger">{_('NOT_ACTIVE')}</span>  
          }
          </a>
        </td>
        <td>
          <ul className="list-unstyled hstack gap-1 mb-0">
            <li>
                <Link to={`/dashboard/email-automation/${id}/schedule/${slideData?.id}/edit`} className="btn btn-sm btn-soft-info" title="Edit"><i className="mdi mdi-pencil-outline"></i></Link>
            </li>
            <li>
              <a href="javascript:void(0)" onClick={deleteRecord} className="btn btn-sm btn-soft-danger" title="Delete"><i className="mdi mdi-delete-outline"></i></a>
            </li>
          </ul>
        </td>
    </tr>
    <tr className="data-graph">
      <td colspan="11" className="data-graph-child">
        <EmailAutomationScheduleGraph slideData={slideData} />
      </td>
    </tr>
  </>
  );
}

export default ScheduleAutomationSlide;

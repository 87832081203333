import axios from "axios";
import {BASE_URL} from "../constants";

export const EMAIL_TEMPLATE = 'EMAIL_TEMPLATE';
export const DELETE_EMAIL_TEMPLATE = 'DELETE_EMAIL_TEMPLATE';
export const EMAIL_TEMPLATE_STATUS = 'EMAIL_TEMPLATE_STATUS';

export const getEmailTemplate = (props) => {
    return axios({
        method: 'GET',
        params: props,
        url: `/email/template/list`
    });
};
export function getEmailTemplateById(id){
    return axios({
        method: 'GET',
        // params: payload,
        url: '/email/template/'+id,
    })
}
export const addEmailTemplate = (props) => {
    return axios({
        method: 'POST',
        data: props,
        url: '/email/template'
    });
};
export function editEmailTemplate(payload, id){
    console.log('-----', id);
    return axios({
        method: 'PATCH',
        data: payload,
        url: '/email/template/'+id
    })
}
export function deleteEmailTemplate(id){
    return axios({
        method: 'DELETE',
        url: '/email/template/'+id
    })
}

export function updateEmailTemplateStatus(payload, id){
    // console.log('-----', id);
    return axios({
        method: 'PATCH',
        data: payload,
        url: '/email/template/'+id+'/status'
    })
}
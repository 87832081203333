import React, { useState } from 'react'
import Inputfield from '../FormFields/InputField'
import { useForm } from "react-hook-form";
import {_} from '../../locale';
import { Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { forgotPassword } from '../../actions/auth';

const ForgotPassword = () => {
    // use Hooks
  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm();
  const [emailVerify, setEmailVerify] = useState(false);


  const onSubmit = async(formValues) => {
    try {
      const resp = await forgotPassword({...formValues});
      console.log('passwordfor', resp);
      
      if (resp?.data) {
        setEmailVerify(true);

        //for model popup of shown
        setTimeout(() => {
          setEmailVerify(false);
        }, 2000);
      }
    reset({email:""})
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_('NO_INTERNET'));
      }
    }
  };

  return (
    <div className="forgot-password">
        <div className="forgot-password-inner">
          <div className="form-step-center">
            <h3 className="h3 text-grey-8 fw700 form-title">
              Forgot Your Password
            </h3>
            <p className="p fs-14 text-grey-6">
              No worries, You will receive an email with instruction to reset
              your password.
            </p>
          </div>

          <form className="form-wrpr" onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-3">
          <label htmlFor="username" className="form-label">{_('EMAIL')}</label>
          {
            <Inputfield
              control={control}
              name={"email"}
              placeholder={_('EMAIL')}
              normalize={(e) => e.replace(/^\s+/g, "")}
              type="email"
              inputClassName={"form-control custom-input"}
              labelField={"username"}
              rules={{
                required: { value: true, message: _('EMAIL_REQUIRED') },
                pattern: {
                  value:
                    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
                  message: _('EMAIL_VALID'),
                },
              }}
            />
          }
        </div>

           <div className="mt-3 d-grid">
          <button
            type="submit"
            disabled={isSubmitting}
            className={`btn btn-primary waves-effect waves-light btn-bg ${
              isSubmitting ? "btn-loader" : ''
            }`}
          >
            {_('SEND')}
          </button>
        </div>
          </form>
          <p className="text-center mt-4 d-flex align-items-center justify-content-center">
            <Link to="/" className="gray-link fs-14">
              Back to Login
            </Link>
          </p>
        </div>

        {/** Start Email Verification Note -- (add "in" class on "emailVerification" for show the verifi pop up )  **/}
        {/* <div className={`emailVerification ${emailVerify && "in"}`}>
          <div className="content-inner">
            <img src="/assets/images/verifi.png" />
            <h3 className="h3 grey-9 vf-heading">Email Verification</h3>
            <p className="vf-content">
              We have send an email. Please verify your email to get started
            </p>
          </div>
        </div> */}
        {/** End Email Verification  **/}
      </div>
  )
}

export default ForgotPassword
import * as Yup from "yup";


export const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string().required("Email is required"),
  
  // options:Yup.array().of(
  //   Yup.object().shape({
  //     title: Yup.string().required("Title is required"),
  //   })
  // ),
});

  
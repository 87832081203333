import axios from "axios";
import {BASE_URL} from "../constants";

export const EMAIL_LIST_STATS = 'EMAIL_LIST_STATS';

export const getEmailStats = (props) => {
    return axios({
        method: 'GET',
        params: props,
        url: `/emailListStats`
    });
};

import { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm, useFieldArray } from 'react-hook-form';
import { _ } from "../../locale";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import Inputfield from "../../components/FormFields/InputField";
import InputTextAreaField from "../../components/FormFields/InputTextAreaField";
import ReactSelectField from "../../components/FormFields/ReactSelectField";
import {INTERVAL_TYPE, CALCULATE_FROM} from "../../constants";
import { addEmailScheduleAutomation } from "../../actions/emailAutomation";
import { getEmailList } from "../../actions/emailList";
import { getEmailTemplate } from "../../actions/emailTemplate";

function AddScheduleAutomation() {
  const { id } = useParams();

  let defaultValues = {
    fromName: "",
    fromEmail: "", 
    emailListIds: null,
    calculateFrom: ""
  };
  const { handleSubmit, control, setValue, reset, getValues, setError, formState: { isSubmitting } } = useForm({defaultValues});
  
  const [emailList, setEmailList] = useState(null);
  const [emailTemplate, setEmailTemplate] = useState(null);
  // const [getIntervalTyp, setIntervalTyp] = useState(1);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // on form submissions
  const onSubmit = async (formData) => {
    console.log('asasaaa', formData);
    try {
      await addEmailScheduleAutomation(formData, id);
      toast.success(_('AUTOMATION_SCHEDULED_SUCCESSFULLY'));
      navigate(`/dashboard/email-automation/${id}/schedule`);
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_('NO_INTERNET'));
      }
    }
  };

  const getEmailListing = async (id) => {
    try {
      let resp = await getEmailList();
      let obj = resp?.data?.responseData?.data ?? [];
      let emails = [];
      obj?.length > 0 && obj.map((ock, index) => {
        emails.push({'label': ock.title, 'value': ock?.id})
      })

      setEmailList(emails);
    } catch (error) {
      console.error(error);
    }
  }

  const getEmailTemplateListing = async (id) => {
    try {
      let resp = await getEmailTemplate();
      let obj = resp?.data?.responseData?.data ?? [];

      let template = [];
      obj?.length > 0 && obj.map((ock, index) => {
        template.push({'label': ock.title, 'value': ock?.id})
      })


      setEmailTemplate(template);
    } catch (error) {
      console.error(error);
    }
  }

  // const chkIntervalType = (e) => {
  //   console.log('999999', e);
  //   setIntervalTyp(e);
  // }

  useEffect(() => {
    getEmailListing();
    getEmailTemplateListing();
  }, [])


  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 font-size-18">
                  {_("ADD_SCHEDULE_AUTOMATION")}
              </h4>
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard/email-automation">{_("AUTOMATION")}</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={`/dashboard/email-automation/${id}/schedule`}>{_("SCHEDULE_AUTOMATION")}</Link>
                  </li>
                  <li className="breadcrumb-item active">{_("ADD")}</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">{_("SCHEDULE_AUTOMATION")}</h4>
                <p className="card-title-desc">{_("SCHEDULE_AUTOMATION_INFO")}</p>

                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-box">
                    <div className="row">
                      <div className="mb-3 col-md-6 plr-0">
                        <label className="form-label">{_('FROM_NAME')}</label>
                        <Inputfield
                          control={control}
                          name={"fromName"}
                          placeholder={_('FROM_NAME')}
                          normalize={(e) => e.replace(/^\s+/g, "")}
                          type="text"
                          inputClassName={"form-control"}
                          rules={{
                            required: { value: true, message: _('FROM_NAME_REQUIRED') },
                          }}
                        />
                      </div>
                      <div className="mb-3 col-md-6 plr-0">
                        <label className="form-label">{_('FROM_EMAIL')}</label>
                        <Inputfield
                          control={control}
                          name={"fromEmail"}
                          placeholder={_('FROM_EMAIL')}
                          normalize={(e) => e.replace(/^\s+/g, "")}
                          type="text"
                          inputClassName={"form-control"}
                          rules={{
                            required: { value: true, message: _('FROM_EMAIL_REQUIRED') },
                          }}
                        />
                      </div>
                      
                    
                      <div className="mb-3 col-md-6 plr-0">
                        <label className="form-label">{_('CALCULATE_FROM')}</label>
                        <ReactSelectField
                          control={control}
                          name="calculateFrom"
                          label={_('CALCULATE_FROM')}
                          placeholder={_('CALCULATE_FROM')}
                          options={CALCULATE_FROM}
                          optionValue="value"
                          optionLabel="label"
                          normalize={(_value) => _value?.value}
                          rules={{
                            required: {
                              value: true,
                              message: _('CALCULATE_FROM_REQUIRED'),
                            },
                          }}
                        />
                      </div>
                      <div className="mb-3 col-md-6 plr-0">
                        <label className="form-label">{_('EMAIL_LIST')}</label>
                        <ReactSelectField
                          control={control}
                          name="emailListIds"
                          label={_('EMAIL_LIST')}
                          placeholder={_('EMAIL_LIST')}
                          options={emailList}
                          multi="true"
                          optionValue="value"
                          optionLabel="label"
                          normalize={(_value) => _value?.value}
                          rules={{
                            required: { value: true, message: _('EMAIL_LIST_REQUIRED') },
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  
                  
                  

                  <div className="d-flex flex-wrap gap-2">
                    <button type="submit" disabled={isSubmitting} className={`btn btn-primary waves-effect waves-light ${isSubmitting && "btn-loader"}`}>
                      {_('SAVE')}
                    </button>
                    <Link to={`/dashboard/email-automation/${id}/schedule`} className="btn btn-secondary waves-effect">
                      {_('CANCEL')}
                    </Link>
                  </div>
                </form>


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddScheduleAutomation;
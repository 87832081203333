import { useController } from "react-hook-form";
import { errorType } from "../../constants";

function Inputfield({
  control,
  rules,
  name,
  defaultValue,
  placeholder,
  type,
  inputClassName,
  label,
  onChange,
  errorClass,
  normalize,
  labelField
}) {
  
  // usehooks
  const {
    field,
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue });

  //   on change Input field
  const onInputChange = (e) => {
    // send value to onChange function
    if (onChange) onChange(normalize(e.target.value.replace(/  +/g, " ")));

    // send value to hook form
    field.onChange(normalize(e.target.value.replace(/  +/g, " ")));
  };

  return (
    <>
      <input
        {...field}
        name={name}
        className={inputClassName ? inputClassName : "form-control"}
        placeholder={placeholder}
        type={type ? type : "text"}
        onChange={(e) => onInputChange(e)}
        id={labelField ? labelField : ''}
      />
      {errorType?.map((type) => {
        if (error?.type === type && error?.message !== "") {
          return (
            <span key={type} className={errorClass || "error-msg"}>
              {error?.message}
            </span>
          );
        }
      })}
    </>
  );
}


/**
 * @property defaults
 */
 Inputfield.defaultProps = {
  defaultValue: "",
  rules: {},
  errorClass: "error-msg",
  onChange: (value) => value,
  normalize: (value) => value,
};

export default Inputfield;

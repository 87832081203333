import { USER_LISTING, DELETE_USER, USER_STATUS} from "../actions/user";

const initialState = {
  userListing: null
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case USER_LISTING:
      return {
        ...state,
        userListing: action.payload
      };
    break;
    case DELETE_USER:
      const member = {...state.userListing};
      let index = member?.data?.findIndex((item) => item.id === action.id);
      member?.data?.splice(index, 1);
      return {
        ...state,
        userListing: {...member},
      };
    break;
    case USER_STATUS:
      let user = { ...state.userListing };
      console.log('00000', user)
      let endex = user?.data?.findIndex((item) => item.id === action?.payload?.id);
      user.data[endex] = {...user.data[endex], ...action.payload}
      return {
        ...state,
        userListing: {...user},
      };
    break;

    default:
  }
  return state;
}
export default user;
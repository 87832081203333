import { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm, useFieldArray } from 'react-hook-form';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import CustomConfirmAlert from '../../components/common/CustomConfirmAlert';
import { _ } from "../../locale";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { getAutomationTree, deleteScheduleInternval } from "../../actions/emailAutomation";
import {SCHEDULER_TYPE, INTERVAL_TYPE} from "../../constants";
import styled from "styled-components";
import { Tree, TreeNode } from "react-organizational-chart";
import AddWorkFlowThread from "./AddWorkFlowThread";

const OrganizationHierarchy = () => {
  const { id, scheduleId } = useParams();
  const [hierarchyData, setHierarchyData] = useState([]);
  const [nodeDetail, setNodeDetail] = useState(null);
  const [showAddNodeModal, setShowAddNodeModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const deleteRecord = (detail) => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <CustomConfirmAlert
          onClose={onClose}
          confirmationMsg={_('CONFIRM_DELETE')}
          actionIcon = {"/assets/images/alert-delete-icon.png"}
          onConfirm={async() => {
            // Perform delete operation here
            try {
              await deleteScheduleInternval(scheduleId, detail?.id);
              fetchRecords();
              toast.info(_('CONFIRM_DELETE_SUCCESS'));
            } catch (error) {
              console.log(error);
            }
          }}
          confirmationIcon="alert-delete-icon.png"
        />
      ),
    });
  };

  const fetchRecords = async () => {
    try {
      let resp = await getAutomationTree({scheduleId});
      let obj = resp?.data?.responseData;
      setHierarchyData(obj);
    } catch (error) {
      console.error(error);
    }
  }

  const addWorkFlowModal = async (node, stat) => {
    setNodeDetail(node);
    setEditModal(stat);
    setShowAddNodeModal(true);
  }

  useEffect(() => {
    fetchRecords();
  }, []);

  // console.log(`hierarachy Dataaa`, hierarchyData);
  const Container = styled.div`
    // display: flex;
    // justify-content: center;
    // align-items: center;
    height: 70vh;
    overflow: auto;
    // padding-top: 80px;
    no-scrollbar
    // padding-bottom: 180px
  `;
  const StyledNode = styled.div`
    padding: 8px 16px;
    border-radius: 8px;
    border: 1px solid #f4c9ff;
    background: #fff;
    font-size: 14px;
    font-weight: 500;
    color: #131007;
    line-height: 1.128;
    letter-spacing: -0.12px;
    min-height: 56px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 135px;
    gap: 10px;
  `;
  const renderHierarchy = (node) => {console.log('434333333', node);

    return (
      <TreeNode
        key={node.id}
        label={
          <StyledNode className="depart-role">
            {node.title}
            <div className="department">{_('NODE_MSG')} {node?.interval} {node?.intervalType ? INTERVAL_TYPE[node?.intervalType-1].label : null}</div>
            <div className="department-action">
              <a href="javascript:void(0)" className="badge badge-pill badge-soft-success font-size-12" onClick={() => addWorkFlowModal(node, false)} title={_('ADD')}>{_('ADD')}</a>
              <a href="javascript:void(0)" className="badge badge-pill badge-soft-warning font-size-12" onClick={() => addWorkFlowModal(node, true)} title={_('EDIT')}>{_('EDIT')}</a>
              <a href="javascript:void(0)" className="badge badge-pill badge-soft-danger font-size-12" onClick={() => deleteRecord(node)} title={_('DELETE')}>{_('DELETE')}</a>
            </div>
          </StyledNode>
        }
        nodeLabelComponent={({ nodeDatum }) => (
          <g>
            <rect
              width={nodeDatum.title.length * 10}
              height={20}
              //fill={generateColor(nodeDatum.depth)}
              rx={5}
            />
            <text x={10} y={12} fill="white" fontSize={12}>
              {nodeDatum.title}
            </text>
          </g>
        )}
      >
        {node.children && node.children.map((child) => renderHierarchy(child))}
      </TreeNode>
    );
  };

  const topLevelData = hierarchyData.length > 0 ? hierarchyData[0] : null;
  const topLevelChildren = topLevelData && topLevelData.children;
  const [opacity, setOpacity] = useState(0);
  const [scaleChar, setScaleChar] = useState(1);

  const scrollContainerRef = useRef(null);
  useEffect(() => {
    setTimeout(() => {
      const container = scrollContainerRef.current;
      const initialScrollPosition =
        container.scrollWidth / 2 - container.clientWidth / 2;
      container.scrollLeft = initialScrollPosition;
    }, 2000);
  }, []);

  const zoomInHandler = () => {
    if (scaleChar <= 1) {
      setScaleChar((prevCount) => (prevCount = prevCount + 0.1));
    }
  };
  const zoomOutHandler = () => {
    if (scaleChar > 0.6) {
      setScaleChar((prevCount) => (prevCount = prevCount - 0.1));
    }
  };
  return (
    <div className="temp-add-preview ">
      <div className="temp-preview-inner">
          <div className="temp-add-locat no-scrollbar">
            <div className="hierarchy-timeline no-scrollbar">
               <div className="d-flex gap-2 p-3 align-items-center justify-content-end zoomIn">
                  <div className="rs-btn-item" onClick={zoomInHandler}>
                    <span
                      style={{ cursor: "pointer" }}
                      className="top-btnw undo-btn"
                    >
                      <img
                        src="/assets/images/minus-icon.svg"
                        
                      />
                    </span>
                  </div>
                    <span className="zoom-ratio">{`${Math?.trunc(
                      scaleChar * 100
                    )}%`}</span>
                  <div className="rs-btn-item"  onClick={zoomOutHandler}>
                    <span
                      style={{ cursor: "pointer" }}
                      className="top-btnw redo-btn"
                    >
                      <img
                        src="/assets/images/plus-icon.svg"
                       
                      />
                    </span>
                  </div>
                  <div className="add-more-node"><a href="javascript:void(0)" className="btn btn-success inner" onClick={() => addWorkFlowModal({'id': null}, false)}>{_('ADD_NEW')}</a></div>
               </div>
              <Container
                id="map"
                ref={scrollContainerRef}
                className={hierarchyData.length ? "no-scrollbar" : "map-node"}
                style={{ paddingBottom: "100px" ,scale: `${scaleChar}`}}
              >
                <Tree lineWidth={"1px"} lineColor={"#c0bdb3"} lineBorderRadius={"10px"}>
                  { hierarchyData.length ? hierarchyData.map((node) => renderHierarchy(node)) : <div className="first-node">{_('CREATE_FIRST_NODE')} <a href="javascript:void(0)" className="btn btn-success inner" onClick={() => addWorkFlowModal({'id': null}, false)}>{_('ADD_NEW')}</a></div>}
                </Tree>
              </Container>
            </div>
          </div>

        </div>
        {
          showAddNodeModal && (
            <AddWorkFlowThread
              show={showAddNodeModal}
              onHide={() => setShowAddNodeModal(false)}
              nodeDetail={nodeDetail}
              reload={fetchRecords}
              scheduleId={scheduleId}
              editModal={editModal}
            />
        )}



    </div>
  );
};

export default OrganizationHierarchy;
import { Route, Routes } from "react-router-dom";
import Header from "../../components/common/Header";
import LeftNavigation from "../../components/common/LeftNavigation";
import Dashboard from "../dashboard/Dashboard";
import ManageEmailList from "../emailList/ManageEmailList";
import AddEmailList from '../emailList/AddEmailList';
import EditEmailList from '../emailList/EditEmailList';
import ManageEmailRecord from "../emailRecord/ManageEmailRecord";
import AddEmailRecord from "../emailRecord/AddEmailRecord";
import EditEmailRecord from "../emailRecord/EditEmailRecord";
import ImportEmailRecordCsv from "../emailRecord/ImportEmailRecordCsv";
import ManageEmailTemplate from "../emailTemplate/ManageEmailTemplate";
import AddEmailTemplate from '../emailTemplate/AddEmailTemplate';
import EditEmailTemplate from '../emailTemplate/EditEmailTemplate';
import ManageAutomation from "../automation/ManageAutomation";
import AddAutomation from '../automation/AddAutomation';
import EditAutomation from '../automation/EditAutomation';
import SchedleAutomation from "../automation/SchedleAutomation";
import AddSchedleAutomation from "../automation/AddSchedleAutomation";
import EditSchedleAutomation from "../automation/EditSchedleAutomation";
import ManageCampaign from "../campaign/ManageCampaign";
import AddCampaign from '../campaign/AddCampaign';
import EditCampaign from '../campaign/EditCampaign';
import ManageScheduleCampaign from '../campaign/ManageScheduleCampaign';
import AddScheduleCampaign from '../campaign/AddScheduleCampaign';
import EditScheduleCampaign from '../campaign/EditScheduleCampaign';
import ManageApiKey from "../settings/ManageApiKey";
import WorkFlow from "../automation/WorkFlow";
import ManageEmailSegment from "../segment/ManageEmailSegment";

function Layout() {
  return (
    <div id="layout-wrapper">
      <Header />
      <LeftNavigation />
      <div className=" overflow-auto">
        <Routes>
          <Route index element={<Dashboard />} />
          <Route path="/analytics" element={<Dashboard />} />
          {/* EMAIL LIST */}
          <Route path="/email-list" element={<ManageEmailList />} />
          <Route path="/email-list/add" element={<AddEmailList />} />
          <Route path="/email-list/:id/edit" element={<EditEmailList />} />
          {/* EMAIL RECORD */}
          <Route path="/email-list/:id/record" element={<ManageEmailRecord />} />
          <Route path="/email-list/:id/record/add" element={<AddEmailRecord />} />
          <Route path="/email-list/:id/record/:recordId/edit" element={<EditEmailRecord />} />
          <Route path="/email-list/:id/record/import-csv" element={<ImportEmailRecordCsv />} />
          {/* EMAIL SEGMENT */}
          <Route path="/email-list/:id/segment" element={<ManageEmailSegment />} />
          {/* EMAIL TEMPLATE */}
          <Route path="/email-template" element={<ManageEmailTemplate />} />
          <Route path="/email-template/add" element={<AddEmailTemplate />} />
          <Route path="/email-template/:id/edit" element={<EditEmailTemplate />} />
          {/* EMAIL AUTOMATION */}
          <Route path="/email-automation" element={<ManageAutomation />} />
          <Route path="/email-automation/add" element={<AddAutomation />} />
          <Route path="/email-automation/:id/edit" element={<EditAutomation />} />
          {/* SCHEDULE AUTOMATION */}
          <Route path="/email-automation/:id/schedule" element={<SchedleAutomation />} />
          <Route path="/email-automation/:id/schedule/add" element={<AddSchedleAutomation />} />
          <Route path="/email-automation/:id/schedule/:scheduleId/edit" element={<EditSchedleAutomation />} />
          <Route path="/email-automation/:id/schedule/:scheduleId/work-flow" element={<WorkFlow />} />
          
          {/* EMAIL CAMPAIGN */}
          <Route path="/email-campaign" element={<ManageCampaign />} />
          <Route path="/email-campaign/add" element={<AddCampaign />} />
          <Route path="/email-campaign/:id/edit" element={<EditCampaign />} />
          {/* SCHEDULE CAMPAIGN */}
          <Route path="/email-campaign/:id/schedule" element={<ManageScheduleCampaign />} />
          <Route path="/email-campaign/:id/schedule/add" element={<AddScheduleCampaign />} />
          <Route path="/email-campaign/:id/schedule/:scheduleId/edit" element={<EditScheduleCampaign />} />
          {/* SCHEDULE AUTOMATION */}
          <Route path="/api-key" element={<ManageApiKey />} />
        </Routes>
      </div>
    </div>
  );
}

export default Layout;

import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Inputfield from "../../components/FormFields/InputField";
import { removeEmptyFields } from "../../constants";

function EmailFilter({ getFilterFields }) {
  // use hooks
  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting },
  } = useForm();

  const navigate = useNavigate();

  const onSubmit = (data) => {
    const values = removeEmptyFields(data);
    const queryParams = new URLSearchParams(values);
    navigate({
      search: queryParams.toString(),
    });
    getFilterFields(values);
  };

  const resetFilter = () => {
    navigate({
      search: "",
    });
    getFilterFields({});
    // reset({ search });
  };


  return (
    <div className="leads-filter filter-style">
      <form name="filter" onSubmit={handleSubmit(onSubmit)} className="chk-filter">
      	<div className="fieldInput">
      		<Inputfield
              	control={control}
              	label="Search"
              	normalize={(e) => e.replace(/^\s+/g, "")}
              	placeholder=""
              	name="searchText"
              	type="text"
              	fieldClass="form-control input-search"
            />
      	</div>
      	<div class="fieldAction">
      		<button type="submit" disabled={isSubmitting} className={`btn btn-dark waves-effect waves-light btn-style ${isSubmitting ? "btn-loader" : '' }`} > Apply </button>
      		<button type="button" onClick={resetFilter} className="btn btn1 btn-center" > Reset </button>
        </div>
      </form>
    </div>
  );
}

export default EmailFilter;

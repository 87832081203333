export const locale = {
    //start english
    en: {
        'PRODUCT_NAME': 'Email Automation',
        'SIGN_IN_CONTINUE': 'Email automation and marketing platform',
        'CRAFTED_WITH': 'Email automation. Crafted with',
        'BY_ILLUMINZ': 'by Illuminz',
        'NO_INTERNET': 'Something went wrong, please retry',
        'EMAIL': 'Email',
        'EMAIL_REQUIRED': 'Email is required',
        'EMAIL_VALID': 'Please enter valid email address',
        'PASSWORD': 'Password',
        'PASSWORD_REQUIRED': 'Password is required',
        'PASSWORD_VALIDATE': 'Password must be more than four characters',
        'CREATE_PASSWORD': 'Create Password',
        'CONFIRM_PASS': 'Confirm Password',
        'LOGIN': 'Log In',
        'NAME': 'Name',
        'ACTION': 'Action',
        'ADD': 'Add',
        'TITLE': 'Title',
        'TITLE_REQUIRED': 'Title is required',
        'DESCRIPTION': 'Description',
        'DESCRIPTION_REQUIRED': 'Description is required',
        'SAVE': 'Save',
        'CANCEL': 'Cancel',
        'STATUS': 'Status',
        'ACTIVE': 'Active',
        'NOT_ACTIVE': 'Not Active',
        'EDIT': 'Edit',
        'EMAIL_LIST': 'Email List',
        'CREATE_EMAIL_LIST': 'Add email list',
        'SOMETHING_WENT_WRONG_WITH_EXCEPTION': 'Something went wrong with exception',
        'EMAIL_LIST_INFO': 'Detail required to create a new email list',
        'NAME_REQUIRED': 'Name is required',
        'CREATED_BY': 'Created by',
        'UPDATED_BY': 'Updated by',
        'UPDATE_EMAIL_LIST': 'Update email list',
        'EMAIL_RECORD': 'Email record',
        'CREATE_NEW': 'Add new',
        'CREATE_EMAIL_RECORD': 'Add new email record',
        'EMAIL_RECORD_INFO': 'Detail required to create a new email record',
        'FIRST_NAME': 'First name',
        'LAST_NAME': 'Last name',
        'META_KEY': 'Meta key',
        'META_VALUE': 'Meta value',
        'EDIT_EMAIL_RECORD': 'Edit email record',
        'SUBSCRIBED': 'Subscribed',
        'BOUNCED': 'Bounced',
        'BLOCKED': 'Blocked',
        'EMAIL_TEMPLATE': 'Email Template',
        'CREATE_EMAIL_TEMPLATE': 'Add email template',
        'EMAIL_AUTOMATION': 'Email Automation',
        'UPDATE_EMAIL_AUTOMATION': 'Update email automation',
        'EMAIL_AUTOMATION_INFO': 'Detail required to create a new email automation',
        'CREATE_EMAIL_AUTOMATION': 'Add email automation',
        'ALL_RIGHTS_RESERVED': 'All rights reserved',
        'DASHBOARD': 'Dashboard',
        'MANAGEMENT': 'Management',
        'SCHEDULE_AUTOMATION': 'Schedule automation',
        'SCHEDULE_AUTOMATION_INFO': 'Detail required to scheulde an automation email',
        'FROM_EMAIL': 'From email',
        'INTERVAL_TYPE': 'Interval type',
        'CALCULATE_FROM': 'Calculate from',
        'SCHEDULE': 'Schedule',
        'INTERVAL': 'Interval',
        'EDIT_EMAIL_TEMPLATE': 'Edit email template',
        'EMAIL_TEMPLATE_INFO': 'Detail required to create a new template',
        'CODE': 'Code',
        'SUBJECT': 'Subject',
        'REPLACEMENTS': 'Replacements',
        'MESSAGE': 'Message',
        'IS_DEFAULT': 'Default?',
        'UPLOAD_FILES': 'Upload files',
        'IMPORT_CSV': 'Import CSV',
        'EXPORT_CSV': 'Export CSV',
        'SEND_TEMPLATE': 'Send template',
        'EMAIL_CAMPAIGN': 'Email campaign',
        'CREATE_EMAIL_CAMPAIGN': 'Add email campaign',
        'EMAIL_CAMPAIGN_INFO': 'Detail required to create an email compaign',
        'UPDATE_EMAIL_CAMPAIGN': 'Update email campaign',
        'SCHEDULE_CAMPAIGN': 'Schedule campaign',
        'SCHEDULE_CAMPAIGN_INFO': 'Detail required to schedule a campaign',
        'CAMPAIGN': 'Campaign',
        'CAMPAIGN_TIMING': 'Campaign time',
        'CONFIRM_DELETE': 'Please confirm you wish to delete this record...?',
        'CONFIRM_DELETE_SUCCESS': 'Record has been deleted successfully.',
        'BY': 'By',
        'TOTAL_RECORDS': 'Total records',
        'TOTAL_SUBSCRIBED': 'Total subscribed',
        'TOTAL_UNSUBSCRIBED': 'Total unsubscribed',
        'AUTOMATION': 'Automation',
        'MANAGE': 'Manage',
        'SCHEDULE_EMAIL_CAMPAIGN': 'Schedule email campaign',
        'SEND_AT': 'Send at',
        'SEND_ON': 'Send on',
        'CAMPAIGN_SCHEDULED_SUCCESSFULLY': 'Campaign has been scheduled successfully',
        'FROM_EMAIL_REQUIRED': 'From email is required',
        'EMAIL_TEMPLATE_REQUIRED': 'Please choose email template first',
        'CAMPAIGN_TIMING_REQUIRED': 'Campaign timing is required',
        'EMAIL_LIST_REQUIRED': 'Please choose atleast one email list',
        'FROM_NAME': 'From name',
        'FROM_NAME_REQUIRED': 'From name is required',
        'UPLOAD_EMAIL_RECORD_CSV': 'Upload CSV',
        'UPLOAD': 'Upload',
        'TOTAL_EMAIL_LISTS': 'Total email lists',
        'TOTAL_EMAIL_RECORDS': 'Total email records',
        'TOTAL_SUBSCRIBERS': 'Total subscribers',
        'TOTAL_UNUBSCRIBERS': 'Total unubscribers',
        'EMAIL_LIST_STATS': 'Email list stats',
        'CONFIRM_DEACTIVATE': 'Please confirm you wish to deactivate this record...?',
        'CONFIRM_ACTIVATE': 'Please confirm you wish to activate this record...?',
        'LIST_ID': 'List ID',
        'API_KEY': 'API key',
        'MANAGE_API_KEY': 'Manage api key',
        'MANAGE_API_KEY_INFO': 'Generate or copy api key from here',
        'KEY_GENERATED_SUCCESSFULLY': 'Key has been generated successfully',
        'COPIED_SUCCESSFULLY': 'Key has been copied successfully',
        'COPY': 'Copy',
        'GENERATE': 'Generate',
        'REGENERATE': 'Regenerate',
        'SUBSCRIPTION_STATS': 'Subscription stats',
        'UNSUBSCRIBERS': 'Unsubscribers',
        'SUBSCRIBERS': 'Subscribers',
        'INTERVAL_TYPE_REQUIRED': 'Interval type is required',
        'CALCULATE_FROM_REQUIRED': 'Calculate-from is required',
        'SCHEDULE_EMAIL_AUTOMATION': 'Schedule email automation',
        'TOTAL_SENT': 'Total sent',
        'DELIVERED': 'Delivered',
        'WORKFLOW': 'Workflow',
        'CREATED': 'Created',
        'EDIT_SCHEDULE_AUTOMATION': 'Edit automation schedule',
        'ADD_SCHEDULE_AUTOMATION': 'Schedule new automation',
        'WORK_FLOW': 'Work flow',
        'CONDITION': 'Condition',
        'CONDITION_REQUIRED': 'Please choose the condition first',
        'INTERVAL_REQUIRED': 'Interval is required',
        'CREATE_FIRST_NODE': 'You do not have any work flow created for the schedule. Please click below button to create your first work flow.',
        'ADD_NEW': 'Add new',
        'NODE_MSG': 'It will execute after ',
        'DELETE': 'Delete',
        'EMAIL_SEGMENT': 'Email segment',
        'EMAIL_SEGMENT_INFO': 'Detail required to set email segment',
    },
    //end english

};

import { EMAIL_LIST_STATS} from "../actions/dashboard";

const initialState = {
  emailListStats: null,
};

const dashboard = (state = initialState, action) => {

  switch (action.type) {
    case EMAIL_LIST_STATS:
      return {
        ...state,
        emailListStats: action.payload
      };
    break;

    default:
  }
  return state;
}
export default dashboard;
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-hot-toast";
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';
import { PAGE_LIMIT } from "../../constants";
import {_} from "../../locale";
import ManageAutomationSlide from './ManageAutomationSlide';
import RecordSkelton from './RecordSkelton';
import { EMAIL_AUTOMATION, getEmailAutomationList } from "../../actions/emailAutomation";

function ManageAutomation() {
  // local variables
  const dispatch = useDispatch();
  const list = useSelector(state => state.emailAutomation);
  const [activePage, setActivePage] = useState(1);
  const [showLoader, setShowLoader] = useState(false);

  const fetchData = useCallback( async (page) => {
    setShowLoader(true);
    try {
      const resp = await getEmailAutomationList({ 'page': page});
      dispatch({ type: EMAIL_AUTOMATION, payload: resp?.data?.responseData });
      setShowLoader(false);
    } catch ({response, request}) {
      // console.log(error, '====');
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_('NO_INTERNET'));
      }
      setShowLoader(false);
    }
  },[]);

  const handlePageChange = (page) => {
    setActivePage(page);
  };

  useEffect(() => {
    console.log('wwwwwqqqq');
    fetchData(activePage);
  }, [fetchData, activePage]);

  let limit = list?.emailAutomationListing?.perPage ?? PAGE_LIMIT;

  console.log('list', list);
  return (
    <div className="page-content">
      <div className="container-fluid">
        
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 font-size-18">{_('EMAIL_AUTOMATION')}</h4>
              <div className="flex-shrink-0 gap-2">
                <Link to="/dashboard/email-automation/add" className="btn btn-primary">{_('CREATE_EMAIL_AUTOMATION')}</Link>
            </div>
            </div>
          </div>
        </div>                        

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table align-middle table-nowrap table-hover">
                    <thead className="table-light">
                      <tr>
                        <th scope="col" style={{width: "70px"}}>#</th>
                        <th scope="col">{_('TITLE')}</th>
                        <th scope="col">{_('CREATED_BY')}</th>
                        <th scope="col">{_('UPDATED_BY')}</th>
                        <th scope="col">{_('SCHEDULE')}</th>
                        <th scope="col">{_('ACTION')}</th>
                      </tr>
                    </thead>

                    <tbody>
                      
                      {
                        showLoader == true
                        ?
                        <RecordSkelton count={5} />
                        :
                        (
                         list?.emailAutomationListing?.data?.length > 0
                          ?
                          list && list?.emailAutomationListing?.data?.length > 0 && list?.emailAutomationListing?.data?.map((obj, index) => (
                            <ManageAutomationSlide slideData={obj} key={obj.id} index={index} srno={(activePage - 1) * limit} />
                          ))
                          :
                          <tr>
                            <td colspan="8" className="empty-record-msg">
                              <h5 className="text-truncate mb-4 mb-lg-5">No Record exist. <Link to="/dashboard/email-automation/add">Click here</Link> to create new email automation</h5>
                            </td>
                          </tr>
                        )
                      } 
                    
                    </tbody>
                    
                  </table>
                </div>
              </div>
            </div>
            <ResponsivePagination
              current={activePage}
              total={list?.totalPages ?? 1}
              onPageChange={handlePageChange}
            /> 
          </div>
        </div>
      </div> 
    </div>
  );
}
export default ManageAutomation;

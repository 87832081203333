import { Link } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import CustomConfirmAlert from '../../components/common/CustomConfirmAlert';
import { deleteEmailAutomation, DELETE_EMAIL_AUTOMATION } from "../../actions/emailAutomation";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import {_} from "../../locale";
import { useDispatch } from "react-redux";
import { Tooltip as ReactTooltip } from "react-tooltip";
// import "react-tooltip/dist/react-tooltip.css";
import moment from 'moment';

function ManageAutomationSlide({slideData, index, srno}) {
  //   local variables
  const [status, setStatus] = useState(slideData?.status);
  const dispatch = useDispatch();

  const deleteRecord = () => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <CustomConfirmAlert
          onClose={onClose}
          confirmationMsg={_('CONFIRM_DELETE')}
          actionIcon = {"/assets/images/alert-delete-icon.png"}
          onConfirm={async() => {
            // Perform delete operation here
            try {
              await deleteEmailAutomation(slideData?.id);
              dispatch({ type: DELETE_EMAIL_AUTOMATION, id: slideData?.id });
              toast.info(_('CONFIRM_DELETE_SUCCESS'));
            } catch (error) {
              console.log(error);
            }
          }}
        />
      ),
    });
  };

  // const deleteRecord = () => {
  //   confirmAlert({
  //     title: 'Confirm',
  //     message: _('CONFIRM_DELETE'),
  //     buttons: [
  //       {
  //         label: _('YES'),
  //         onClick: async () => {
  //           try {
  //             await deleteEmailAutomation(slideData?.id);
  //             dispatch({ type: DELETE_EMAIL_AUTOMATION, id: slideData?.id });
  //             toast.info("Record has been deleted successfully");
  //           } catch (error) {
  //             console.log(error);
  //           }
  //         }
  //       },
  //       {
  //         label: _('NO')
  //       }
  //     ]
  //   });
  // };

  
  return (
    <tr>
      <td>{parseInt(srno + parseInt(index + 1))}</td>
      <td>
        <p className="font-size-14 mb-1 tooltip-title" id={"email-list-"+slideData?.id}>{slideData?.title ?? null}</p>
        <ReactTooltip anchorId={"email-list-"+slideData?.id} place="top" variant="info" content={<p dangerouslySetInnerHTML={{__html: slideData?.description}} />} />
      </td>
      
      <td>
        <h5 class="font-size-14 mb-1"><a href="javascript: void(0);" class="text-dark">{moment(slideData?.createdAt).format('lll') ?? null}</a></h5>
        <p class="text-muted mb-0">{_('BY')} {slideData?.author?.name ?? null}</p>
      </td>
      <td>
        <h5 class="font-size-14 mb-1"><a href="javascript: void(0);" class="text-dark">{slideData?.updatedAt ? moment(slideData?.updatedAt).format('lll') : moment(slideData?.createdAt).format('ll')}</a></h5>
        <p class="text-muted mb-0">{_('BY')} {slideData?.updatedBy?.name ? slideData?.updatedBy?.name : slideData?.author?.name }</p>
      </td>
      <td>
        <Link to={`/dashboard/email-automation/${slideData.id}/schedule`} className="btn btn-sm btn-soft-info" title="Edit">Schedule</Link>
      </td>
      <td>
        <ul className="list-unstyled hstack gap-1 mb-0">
          <li>
              <Link to={`/dashboard/email-automation/${slideData.id}/edit`} className="btn btn-sm btn-soft-info" title="Edit"><i className="mdi mdi-pencil-outline"></i></Link>
          </li>
          <li>
            <a href="javascript:void(0)" onClick={deleteRecord} className="btn btn-sm btn-soft-danger" title="Delete"><i className="mdi mdi-delete-outline"></i></a>
          </li>
          
        </ul>
      </td>
  </tr>
  );
}

export default ManageAutomationSlide;

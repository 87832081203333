import React from "react";
import { render } from 'react-dom'
import ProgressBar from 'react-bootstrap/ProgressBar';
import {_} from "../../locale";

function EmailAutomationScheduleGraph({slideData}) {

  let notDelivered = slideData?.totalSent - slideData?.delivered;
  let max = notDelivered + slideData?.delivered;
  

  return (
    <ProgressBar>
        <ProgressBar variant="info" max={max} now={slideData?.delivered} key={1} />
        <ProgressBar variant="secondary" max={max} now={notDelivered} key={1} />
      </ProgressBar>

  );
}

export default EmailAutomationScheduleGraph;
import { EMAIL_LIST, DELETE_EMAIL_LIST, EMAIL_LIST_STATUS} from "../actions/emailList";

const initialState = {
  emailListing: null,
};

const emailList = (state = initialState, action) => {

  switch (action.type) {
    case EMAIL_LIST:
      return {
        ...state,
        emailListing: action.payload
      };
    break;
    case DELETE_EMAIL_LIST:
      const data = { ...state.emailListing };
      let index = data?.data?.findIndex((item) => item.id === action.id);
      data?.data?.splice(index, 1);
      return {
        ...state,
        emailListing: data,
      };
    break;
    case EMAIL_LIST_STATUS:
      let template = { ...state.emailListing };
      let endex = template?.data?.findIndex((item) => item.id === action?.payload?.id);
      template.data[endex] = {...template.data[endex], ...action.payload};
      console.log('34343434', template);
      return {
        ...state,
        emailListing: {...template},
      };
    break;

    default:
  }
  return state;
}
export default emailList;
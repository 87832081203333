import React from 'react';
import {_} from "../../locale";
import 'react-confirm-alert/src/react-confirm-alert.css';

const CustomConfirmAlert = ({ onClose, onConfirm, confirmationMsg, actionIcon }) => (
  <div className={"custom-ui "+ actionIcon}>
    <div className="confirm-icon">
      <img src={actionIcon} />
    </div>
    <div className="confirm-msg">
      <p>{confirmationMsg ? confirmationMsg : 'Hi'}</p>
      <div className="confirm-action">
        <button onClick={onClose}>No</button>
        <button
          onClick={() => {
            onConfirm();
            onClose();
          }}
        >
          Yes
        </button>
      </div>
    </div>
  </div>
);

export default CustomConfirmAlert;
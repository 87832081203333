import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { editEmailAutomation, getEmailAutomationById } from "../../actions/emailAutomation";
import {_} from "../../locale";
import Inputfield from "../../components/FormFields/InputField";
import JoditEdior from "../../components/FormFields/JoditEditor";
import { toast } from "react-hot-toast";

function EditAutomation() {
  // use hooks
   const { control, handleSubmit, setError, setValue, reset, formState: { isSubmitting } } = useForm();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();


  // on form submissions
  const onSubmit = async (formData) => {
    console.log('asasaaa', formData);
    try {
      await editEmailAutomation(formData, id);
      navigate('/dashboard/email-automation');
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_('NO_INTERNET'));
      }
    }
  };

  const fetchRecords = useCallback(async () => {
    try {
      let resp = await getEmailAutomationById(id);
      let obj = resp?.data?.responseData;
      setValue("title", obj?.title);
      setValue("description", obj?.description);
    } catch (error) {
      console.error(error);
    }
  }, [dispatch, setValue, id]);

  useEffect(() => {
    fetchRecords();
  }, [])

  
  return (
    <div className="page-content">
      <div className="container-fluid">
        
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 font-size-18">{_('UPDATE_EMAIL_AUTOMATION')}</h4>
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item"><Link to={`/dashboard/email-list`}>{_('EMAIL_AUTOMATION')}</Link></li>
                    <li className="breadcrumb-item active">{_('EDIT')}</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">{_('EMAIL_AUTOMATION')}</h4>
                <p className="card-title-desc">{_('EMAIL_AUTOMATION_INFO')}</p>
                
                <form onSubmit={handleSubmit(onSubmit)}>
                  
                  <div className="row">
                    <div className="col-md-6 form-group">
                      <label className="form-label">{_('TITLE')}</label>
                      <Inputfield
                        control={control}
                        name={"title"}
                        placeholder={_('TITLE')}
                        normalize={(e) => e.replace(/^\s+/g, "")}
                        type="text"
                        inputClassName={"form-control"}
                        rules={{
                          required: { value: true, message: _('TITLE_REQUIRED') },
                        }}
                      />
                    </div>
                    </div>


                    <div className="row">
                      <div className="plr-0">
                        <JoditEdior
                          control={control}
                          name={"description"}
                          label={_('DESCRIPTION')}
                          errorClass="error-msg"
                          rules={{
                            required: {
                              value: true,
                              message: _('DESCRIPTION_REQUIRED'),
                            },
                          }}
                        />
                      </div>
                    </div>
                  

                  <div className="d-flex flex-wrap gap-2">
                    <button type="submit" disabled={isSubmitting} className={`btn btn-primary waves-effect waves-light ${isSubmitting && "btn-loader"}`}>
                      {_('SAVE')}
                    </button>
                    <Link to={`/dashboard/email-automation`} className="btn btn-secondary waves-effect">
                      {_('CANCEL')}
                    </Link>
                  </div>
                </form>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditAutomation;

import React from "react"
import { Field, ErrorMessage } from "formik"

function Input(props) {
  const { name, label,className,errors,  ...rest } = props

  const hasError = errors ? errors["name"] :  null;
  return (
    <div className={`form-group position-relative ${hasError ? "f-error" : ""}`} >
      {label && (<label htmlFor={name} className="form-label"> {label}
    
      </label>
      )}
      <Field name={name} className={`${'form-control'}`} {...rest}  />
      <p className="error">
          <>
         
          <ErrorMessage name={name} /></>
      
  </p>

     
    </div>
  )
}
export default Input
import { useEffect, useState } from "react";
import { _ } from "../../locale";
import { toast } from "react-hot-toast";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { getKeys, generateKey } from "../../actions/settings";

function ManageApiKey() {
  const [detail, setDetail] = useState(null);
  const [copied, setCopied] = useState(false);

  const copyText= () => {
    setCopied(true);
    toast.success(_('COPIED_SUCCESSFULLY'))
  }

  // on form submissions
  const generateNewKey = async () => {
    try {
      let result = await generateKey();
      toast.success(_('KEY_GENERATED_SUCCESSFULLY'));
      setDetail(result?.data?.responseData);
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_('NO_INTERNET'));
      }
    }
  };

  const fetchRecords = async () => {
    try {
      let resp = await getKeys();
      let obj = resp?.data?.responseData;
      setDetail(obj);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchRecords();
  }, [])

  // console.log(detail, 'wewewe', keyCode);


  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 font-size-18">
                  {_("API_KEY")}
              </h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">{_("MANAGE_API_KEY")}</h4>
                <p className="card-title-desc">{_("MANAGE_API_KEY_INFO")}</p>


                <div className="d-flex align-items-center copy-link-wrap flex-wrap">
                  <input type="text" className="style-input" value={detail?.key} readOnly="readOnly" />
                  {
                    detail?.key
                    ?
                    <CopyToClipboard  text={detail?.key} onCopy={() => copyText()}>
                      <span className="kopieren-btn">
                        <img src="/assets/images/copy-icon.svg" alt="" /> {_('COPY')}
                      </span>
                    </CopyToClipboard>
                    :
                    null
                  }
                  
                  <span className="kopieren-btn" onClick={generateNewKey}>{detail?.key ? _('REGENERATE') : _('GENERATE')}</span>
                </div>


                
                  
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageApiKey;
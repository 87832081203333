import { EMAIL_RECORD, DELETE_EMAIL_RECORD, EMAIL_RECORD_STATUS} from "../actions/emailRecord";

const initialState = {
  emailRecord: null,
};

const emailRecord = (state = initialState, action) => {

  switch (action.type) {
    case EMAIL_RECORD:
      return {
        ...state,
        emailRecord: action.payload
      };
    break;
    case DELETE_EMAIL_RECORD:
      const data = { ...state.emailRecord };
      let index = data?.data?.findIndex((item) => item.id === action.id);
      data?.data?.splice(index, 1);
      return {
        ...state,
        emailRecord: data,
      };
    break;
    case EMAIL_RECORD_STATUS:
      let template = { ...state.emailRecord };
      let endex = template?.data?.findIndex((item) => item.id === action?.payload?.id);
      template.data[endex] = {...template.data[endex], ...action.payload};
      return {
        ...state,
        emailRecord: {...template},
      };
    break;

    default:
  }
  return state;
}
export default emailRecord;
import moment from "moment";
import axios from "axios";
import { _ } from "./locale";
import { toast } from "react-hot-toast";

export function removeEmptyFields(data) {
  Object.keys(data).forEach((key) => {
    if (
      data[key] === "" ||
      data[key] == null ||
      data[key]?.length === 0 ||
      data[key] === false
    ) {
      delete data[key];
    }
  });
  return data;
}

export function clearSession() {
  localStorage.clear();
  // redirect on root
  window.location = "/";
}

export function isAuthenticated(token) {
  if (token && token != undefined) {
    return true;
  }
  return false;
}

export function authentication() {
  // With auth token
  if (typeof localStorage.getItem('jwtToken') !== 'undefined' && localStorage.getItem('jwtToken') !== null)
    return true;
  else
    return false;
};

export function firstLetterCapital(str) {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return str;
}

export function toShortString(str, len = 30) {
  if (str?.length >= len) {
    return str.substring(0, len) + " ...";
  } else {
    return str;
  }
}

export const getDates = (dateCode) => {
  let startDate;
  const utcOffset = moment().utcOffset();
  let endDate = moment().add(parseInt(utcOffset), "minutes");
  switch (dateCode) {
    case "TODAY":
      startDate = moment().startOf("day").add(parseInt(utcOffset), "minutes");
      break;
    case "YESTERDAY":
      startDate = moment()
        .startOf("day")
        .add(-1, "days")
        .add(parseInt(utcOffset), "minutes");
      endDate = moment()
        .startOf("day")
        .add(-1, "seconds")
        .add(parseInt(utcOffset), "minutes");
      break;
    case "LAST_7_DAYS":
      startDate = moment().add(-7, "days").add(parseInt(utcOffset), "minutes");
      break;
    case "LAST_30_DAYS":
      startDate = moment().add(-30, "days").add(parseInt(utcOffset), "minutes");
      break;
    case "THIS_MONTH":
      startDate = moment().startOf("month").add(parseInt(utcOffset), "minutes");
      break;
    case "LAST_MONTH":
      startDate = moment()
        .startOf("month")
        .add(-1, "months")
        .add(parseInt(utcOffset), "minutes");
      endDate = moment()
        .startOf("month")
        .add(-1, "seconds")
        .add(parseInt(utcOffset), "minutes");
      break;
    case "LAST_YEAR":
      startDate = moment().startOf("year").add(parseInt(utcOffset), "minutes");
      endDate = moment().endOf("year").add(parseInt(utcOffset), "minutes");
      break;
    default:
      break;
  }

  return { start: startDate.utc().format("YYYY-MM-DD HH:mm:ss"), end: endDate.utc().format("YYYY-MM-DD HH:mm:ss") };
};


export const downloadFile = (setShowLoader,downloadUrl,fileNames) => {
  console.log(downloadUrl, "downloadUrldownloadUrl");
  axios
    .get(downloadUrl, { responseType: "arraybuffer" })
    .then((response) => {
      console.log(response, "ResponsefromAxios");
      const contentDisposition = response.headers["Content-Disposition"];
      console.log(contentDisposition,"qwertyuiop")
      const matches = /filename="(.+)"/.exec(contentDisposition);
      const fileName = matches ? matches[1] : fileNames;

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const url = window.URL.createObjectURL(blob);
      console.log(url, "dbhchjdsbhcjdbc");
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      link.click();
    })
    .catch(({ response, request }) => {
      console.error(response, request, "sdbchhsdjbchjdsbc");
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_("NO_INTERNET"));
      }
      setShowLoader(false);
    });
};

export function objectToQueryString(obj,pathName) {
  const queryString = Object.keys(obj)
    .filter((key) => obj[key] !== undefined)
    .map(
      (key) => encodeURIComponent(key) + "=" + encodeURIComponent(obj[key])
    )
    .join("&");

  return pathName + queryString;
}
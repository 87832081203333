import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const ScheduleSkelton = ({count}) => {
  const items = new Array(count).fill(0);
  return (
    <>
    {
      items.map((item, index) => (
        <tr>
          <td><p className="font-size-14 mb-1"><Skeleton width={15} height={15} /></p></td>
         <td><p className="font-size-14 mb-1"><Skeleton width={155} height={15} /></p></td>
         <td><p className="font-size-14 mb-1"><Skeleton width={155} height={15} /></p></td>
         <td><p className="font-size-14 mb-1"><Skeleton width={155} height={15} /></p></td>
         <td><p className="font-size-14 mb-1"><Skeleton width={155} height={15} /></p></td>
         <td><p className="font-size-14 mb-1"><Skeleton width={155} height={15} /></p></td>
          <td>
            <h5 class="font-size-14 mb-1"><a href="javascript: void(0);" class="text-dark"><Skeleton width={155} height={15} /></a></h5>
          </td>
          <td>
            <h5 class="font-size-14 mb-1"><a href="javascript: void(0);" class="text-dark"><Skeleton width={155} height={15} /></a></h5>
          </td>

          <td><p className="font-size-14 mb-1"><Skeleton width={155} height={15} /></p></td>
          
          <td>
            <ul className="list-unstyled hstack gap-1 mb-0">
            <li>
              <a className="btn btn-sm btn-soft-info" href="javascript:void(0)"><Skeleton width={15} height={15} /></a>
            </li>
            <li>
             <a href="javascript:void(0)" className="btn btn-sm btn-soft-dange" title="Delete"><Skeleton width={15} height={15} /></a>
            </li>
           
          </ul>
          </td>
        </tr>
      ))
    }
    </>
  );
};

export default ScheduleSkelton;
import { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-hot-toast";
import queryString from "query-string";
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';
import { PAGE_LIMIT } from "../../constants";
import {_} from "../../locale";
import ManageEmailListSlide from './ManageEmailListSlide';
import RecordSkelton from './RecordSkelton';
import { EMAIL_LIST, getEmailList } from "../../actions/emailList";
import EmailFilter from "./EmailFilter";

function ManageEmailList() {
  // local variables
  const { search } = useLocation();
  const dispatch = useDispatch();
  const list = useSelector(state => state.emailList);
  const [activePage, setActivePage] = useState(1);
  const [showLoader, setShowLoader] = useState(false);
  const [filters, setFilters] = useState(queryString.parse(search));

  const fetchData = useCallback( async (page, filters) => {
    setShowLoader(true);
    try {
      const resp = await getEmailList({ ...filters, page: page });
      dispatch({ type: EMAIL_LIST, payload: resp?.data?.responseData });
      setShowLoader(false);
    } catch ({response, request}) {
      // console.log(error, '====');
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_('NO_INTERNET'));
      }
      setShowLoader(false);
    }
  },[]);

  const handlePageChange = (page) => {
    setActivePage(page);
  };

  const getFilterFields = (filters) => {
    setActivePage(1);
    setFilters(filters);
  };

  useEffect(() => {
    fetchData(activePage, filters);
  }, [fetchData, activePage, filters]);



  let limit = list?.emailListing?.perPage ?? PAGE_LIMIT;

  console.log('list', list);
  return (
    <div className="page-content">
      <div className="container-fluid">
        
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between email-list">
              <div className="header-container">
                <h4 className="mb-sm-0 font-size-18">{_('EMAIL_LIST')}</h4>
                <EmailFilter getFilterFields={getFilterFields} />
              </div>
              
              <div className="flex-shrink-0 gap-2 header-action-link">
                <Link to="/dashboard/email-list/add" className="btn btn-primary">{_('CREATE_EMAIL_LIST')}</Link>
            </div>
            </div>
          </div>
        </div>                        

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table align-middle table-nowrap table-hover">
                    <thead className="table-light">
                      <tr>
                        <th scope="col" style={{width: "70px"}}>#</th>
                        <th scope="col">{_('LIST_ID')}</th>
                        <th scope="col">{_('TITLE')}</th>
                        <th scope="col">{_('TOTAL_RECORDS')}</th>
                        <th scope="col">{_('TOTAL_SUBSCRIBED')}</th>
                        <th scope="col">{_('TOTAL_UNSUBSCRIBED')}</th>
                        <th scope="col">{_('CREATED_BY')}</th>
                        <th scope="col">{_('UPDATED_BY')}</th>
                        <th scope="col">{_('STATUS')}</th>
                        <th scope="col">{_('ACTION')}</th>
                      </tr>
                    </thead>

                    <tbody>
                      {
                        showLoader == true
                        ?
                        <RecordSkelton count={5} />
                        :
                        (
                         list?.emailListing?.data?.length > 0
                          ?
                          list && list?.emailListing?.data?.length > 0 && list?.emailListing?.data?.map((obj, index) => (
                            <ManageEmailListSlide slideData={obj} key={obj.id} index={index} srno={(activePage - 1) * limit} />
                          ))
                          :
                          <tr>
                            <td colspan="9" className="empty-record-msg">
                              <h5 className="text-truncate mb-4 mb-lg-5">No Record exist. <Link to="/dashboard/email-list/add">Click here</Link> to create new email list</h5>
                            </td>
                          </tr>
                        )
                      } 
                    </tbody>
                    
                  </table>
                </div>
              </div>
            </div>
            <ResponsivePagination
              current={activePage}
              total={list?.totalPages ?? 1}
              onPageChange={handlePageChange}
            /> 
          </div>
        </div>
      </div> 
    </div>
  );
}
export default ManageEmailList;

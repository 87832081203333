import React from "react";
import { render } from 'react-dom'
import ProgressBar from 'react-bootstrap/ProgressBar';
import {_} from "../../locale";

function EmailListGraph({slideData}) {

  let max = slideData?.totalRecords;
  // console.log('99999', max);

  return (
    <ProgressBar>
      <ProgressBar striped variant="info" max={max} now={slideData?.totalSubscribed} key={1} />
      <ProgressBar variant="secondary" max={max} now={slideData?.totalUnsubscribed} key={1} />
    </ProgressBar>

  );
}

export default EmailListGraph;
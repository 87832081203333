import { useEffect } from "react";
import { isAuthenticated } from "../../utilities";
import { useNavigate } from "react-router-dom";
import EmailStats from './EmailStats';
import "../../css/Developer.css";
import "react-tooltip/dist/react-tooltip.css";

function Dashboard() {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated(localStorage.getItem("jwtToken"))) {
      navigate("/");
    }
  }, []);

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 font-size-18">Dashboard</h4>
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item"><a href="javascript: void(0);">Dashboards</a></li>
                  <li className="breadcrumb-item active">Dashboard</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-12 tl-block align-self-center comingsoon">
          <EmailStats />
        </div>

      </div>
    </div>
  );
}

export default Dashboard;

import LoginForm from "./LoginForm";
import {_} from '../../locale';
import { useEffect } from "react";
import { isAuthenticated } from "../../utilities";
import { useNavigate } from "react-router-dom";
import moment from 'moment';

function Login() {
  const navigate = useNavigate();
  useEffect(() => {
    if (isAuthenticated(localStorage.getItem("jwtToken"))) {
      navigate("/dashboard");
    }
  }, []);

  return (
    <div className="account-pages my-5 pt-sm-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-6 col-xl-5">
            <div className="card overflow-hidden loGinForm">
              <div className="">
                <div className="row">
                  <div className="col-12">
                    <div className="text-primary p-4 login-logo">
                      <img src="/assets/images/logo.svg" alt="" style={{width:"75px", }} />
                      <p className="text-dark logo-heading">{_('SIGN_IN_CONTINUE')}.</p>
                    </div>
                  </div>
                  
                </div>
              </div>
              <div className="card-body"> 
                <LoginForm />
              </div>
            </div>
            <div className="mt-5 text-center">
              <div>
                <p>&copy; {moment().format('YYYY')} {_('PRODUCT_NAME')} <i className="mdi mdi-heart "></i>  {_('ALL_RIGHTS_RESERVED')} </p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    
  );
}

export default Login;

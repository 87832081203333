import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams, Link } from "react-router-dom";
import { addEmailScheduleWorkFlow, editEmailScheduleWorkFlow } from "../../actions/emailAutomation";
import { getEmailTemplate } from "../../actions/emailTemplate";
import {_} from "../../locale";
import {SCHEDULER_TYPE, INTERVAL_TYPE} from "../../constants";
import Inputfield from "../../components/FormFields/InputField";
import ReactSelectField from "../../components/FormFields/ReactSelectField";
import { toast } from "react-hot-toast";

function AddWorkFlowThread({nodeDetail, onHide, reload, scheduleId, editModal}) {
  // use hooks
  const {control, handleSubmit, setValue, formState: { isSubmitting }, } = useForm();
  const navigate = useNavigate();
  const [emailTemplate, setEmailTemplate] = useState(null);
  console.log(editModal, '23232323', nodeDetail);

  // on form submissions
  const onSubmit = async (formData) => {
    console.log('asasaaa', formData);
    formData = {...formData, parentInterval: editModal == true ? nodeDetail?.parentInterval : nodeDetail?.id}
    try {
      if(editModal == true){
        await editEmailScheduleWorkFlow(formData, nodeDetail?.id, scheduleId);
      } else {
        await addEmailScheduleWorkFlow(formData, scheduleId);
      }
      
      
      reload();
      onHide();
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_('NO_INTERNET'));
      }
    }
  };

  const getEmailTemplateListing = async (id) => {
    try {
      let resp = await getEmailTemplate();
      let obj = resp?.data?.responseData?.data ?? [];
      let template = [];
      obj?.length > 0 && obj.map((ock, index) => {
        template.push({'label': ock.title, 'value': ock?.id})
      })
      setEmailTemplate(template);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getEmailTemplateListing();
    if(editModal === true){
      // let reqObj = {'title': nodeDetail?.title}
      setValue("title", nodeDetail?.title);
      setValue("emailTemplateId", nodeDetail?.emailTemplateId);
      setValue("condition", nodeDetail?.condition);
      setValue("interval", nodeDetail?.interval);
      setValue("intervalType", nodeDetail?.intervalType);
    }
  }, [])

  
  return (
    <div className="modal fade show modalRight" id="rightModal">
      <div className="modal-dialog modal-dialog-slideout" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="rightModalLabel">{_('WORK_FLOW')}</h5>
            <a href="javascript:void(0)" className="close" onClick={onHide}>
              <span aria-hidden="true">×</span>
            </a>
          </div>
          <div className="modal-body">

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-box">
                <div className="row">
                  <div className="mb-3 col-md-12 plr-0">
                    <label className="form-label">{_('TITLE')}</label>
                    <Inputfield
                      control={control}
                      name={"title"}
                      placeholder={_('TITLE')}
                      normalize={(e) => e.replace(/^\s+/g, "")}
                      type="text"
                      inputClassName={"form-control"}
                      rules={{
                        required: { value: true, message: _('TITLE_REQUIRED') },
                      }}
                    />
                  </div>
                  <div className="mb-3 col-md-12 plr-0">
                    <label className="form-label">{_('EMAIL_TEMPLATE')}</label>
                    <ReactSelectField
                      control={control}
                      name={'emailTemplateId'}
                      label={_('EMAIL_TEMPLATE')}
                      placeholder={_('EMAIL_TEMPLATE')}
                      options={emailTemplate}
                      optionValue="value"
                      optionLabel="label"
                      normalize={(_value) => _value?.value}
                      rules={{
                        required: { value: true, message: _('EMAIL_TEMPLATE_REQUIRED') },
                      }}
                    />
                  </div>
                </div>

                <div className="row">
                  {
                    (nodeDetail?.id == null || (editModal == true && nodeDetail?.parentInterval == null))
                    ?
                    null
                    :
                    <div className="mb-3 col-md-12 plr-0">
                      <label className="form-label">{_('CONDITION')}</label>
                      <ReactSelectField
                        control={control}
                        name="condition"
                        label={_('CONDITION')}
                        placeholder={_('CONDITION')}
                        options={SCHEDULER_TYPE}
                        optionValue="value"
                        optionLabel="label"
                        normalize={(_value) => _value?.value}
                        rules={{
                          required: { value: true, message: _('CONDITION_REQUIRED') },
                        }}
                      />
                    </div>
                  }
                  <div className="mb-3 col-md-12 plr-0">
                    <label className="form-label">{_('INTERVAL_TYPE')}</label>
                    <ReactSelectField
                      control={control}
                      name="intervalType"
                      label={_('INTERVAL_TYPE')}
                      placeholder={_('INTERVAL_TYPE')}
                      options={INTERVAL_TYPE}
                      optionValue="value"
                      optionLabel="label"
                      normalize={(_value) => _value?.value}
                      rules={{
                        required: { value: true, message: _('INTERVAL_TYPE_REQUIRED') },
                      }}
                    />
                  </div>
                  <div className="mb-3 col-md-12 plr-0">
                    <label className="form-label">{_('INTERVAL')}</label>
                    <Inputfield
                      control={control}
                      name={"interval"}
                      placeholder={_('INTERVAL')}
                      normalize={(e) => e.replace(/^\s+/g, "")}
                      type="text"
                      inputClassName={"form-control"}
                      rules={{
                        required: { value: true, message: _('INTERVAL_REQUIRED') },
                      }}
                    />
                  </div>
                </div>
              </div>
              
              <div className="d-flex flex-wrap gap-2">
                <button type="submit" disabled={isSubmitting} className={`btn btn-primary waves-effect waves-light ${isSubmitting && "btn-loader"}`}>
                  {_('SAVE')}
                </button>
                <Link to="javascript:void(0)" onClick={onHide} className="btn btn-secondary waves-effect">
                  {_('CANCEL')}
                </Link>
              </div>
            </form>
          </div>
          {/*}
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={onHide}>Close</button>
            <button type="button" className="btn btn-primary">Save changes</button>
          </div>
          */}
        </div>
      </div>
    </div>
  );
}

export default AddWorkFlowThread;

import { Link } from "react-router-dom";
import UserInfo from './UserInfo';

function Header() {
  return (
    <header id="page-topbar">
      <div className="navbar-header">
        <div className="d-flex">
          <div className="navbar-brand-box">
            <Link to="/" className="logo logo-dark">
              <span className="logo-sm">
                <img src="/assets/images/logo.svg" alt="" height="22" />
              </span>
              <span className="logo-lg">
                <img src="/assets/images/logo.svg" alt="" height="17" />
               </span>
            </Link>
            <Link to="/" className="logo logo-light">
              <span className="logo-sm">
                <img src="/assets/images/logo.svg" alt="" height="50"/>
              </span>
              <span className="logo-lg">
                <img src="/assets/images/logo.svg" alt="" height="50"/>
              </span>
            </Link>
          </div>
          {/*
          <button type="button" className="btn btn-sm px-3 font-size-16 header-item waves-effect" id="vertical-menu-btn">
            <i className="fa fa-fw fa-bars"></i>
          </button>
          */}
        </div>

        <div className="d-flex">
          <UserInfo />
        </div>
      </div>
  </header>
  );
}

export default Header;
import { useState } from 'react';
import { Link } from "react-router-dom";
import { Field, QueryBuilder, RuleGroupType } from 'react-querybuilder';
import { useForm } from "react-hook-form";
import Inputfield from "../../components/FormFields/InputField";
import 'react-querybuilder/dist/query-builder.css';
import { formatQuery } from 'react-querybuilder/formatQuery';
// import { transformQuery } from 'react-querybuilder/transformQuery';
import {_} from "../../locale";
import { toast } from "react-hot-toast";

const fields: Field[] = [
  { name: 'firstName', label: 'First Name' },
  { name: 'lastName', label: 'Last Name' },
  { name: 'age', label: 'Age', inputType: 'number' },
  { name: 'address', label: 'Address' },
  { name: 'phone', label: 'Phone' },
  { name: 'email', label: 'Email', validator: ({ value }) => /^[^@]+@[^@]+/.test(value) },
  { name: 'twitter', label: 'Twitter' },
  { name: 'isDev', label: 'Is a Developer?', valueEditorType: 'checkbox', defaultValue: false },
];

const initialQuery: RuleGroupType = {
  combinator: 'and',
  rules: [],
};

const ManageEmailSegment = () => {
  const {control, handleSubmit, setValue, formState: { isSubmitting }, } = useForm();
  const [query, setQuery] = useState(initialQuery);


  const onSubmit = async (formData) => {
    console.log('asasaaa', formData);
    console.log('8888888', formatQuery(query));
    
    try {
    
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_('NO_INTERNET'));
      }
    }
  };

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 font-size-18">
                  {_("EMAIL_SEGMENT")}
              </h4>
              
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">{_("EMAIL_SEGMENT")}</h4>
                <p className="card-title-desc">{_("EMAIL_SEGMENT_INFO")}</p>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-box">
                    <div className="row">
                      <div className="mb-3 col-md-12 plr-0">
                        <label className="form-label">{_('TITLE')}</label>
                        <Inputfield
                          control={control}
                          name={"title"}
                          placeholder={_('TITLE')}
                          normalize={(e) => e.replace(/^\s+/g, "")}
                          type="text"
                          inputClassName={"form-control"}
                          rules={{
                            required: { value: true, message: _('TITLE_REQUIRED') },
                          }}
                        />
                      </div>
                      <QueryBuilder fields={fields} query={query} onQueryChange={setQuery} />
                    </div>
                  </div>

                  <div className="d-flex flex-wrap gap-2">
                    <button type="submit" disabled={isSubmitting} className={`btn btn-primary waves-effect waves-light ${isSubmitting && "btn-loader"}`}>
                      {_('SAVE')}
                    </button>
                    <Link to="/dashboard/email-list" className="btn btn-secondary waves-effect">
                      {_('CANCEL')}
                    </Link>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default ManageEmailSegment;
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useController } from "react-hook-form";
import moment from "moment";
import { errorType } from "../../constants";

function DateTimePickerComponent({
  name,
  control,
  rules,
  label,
  errorClass,
  onChangeDate, // Add this prop for handling external onChange logic
  minDate,
  maxDate
}) {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control, rules });
  const customStyles = {
    // width: "300px",
  };
  const [date, setDate] = useState(null);
  const currentDate = new Date();
  const [selectedDate, setSelectedDate] = useState(null);


  const handleChangeDate = (selectedDate) => {
    setDate(selectedDate);
    if (selectedDate) {
      setSelectedDate(selectedDate);
      field.onChange(moment(selectedDate).format("MM/DD/YYYY HH:mm"));
      // If an external onChange logic is provided, call it here
      if (onChangeDate) {
        onChangeDate(moment(selectedDate).format("MM/DD/YYYY HH:mm"));
      }
    }
  };

  useEffect(() => {
    if (field.value) {
      const initialDate = moment(field.value, "MM/DD/YYYY HH:mm", true);
      if (initialDate.isValid()) {
        setDate(initialDate.toDate());
      } else {
        console.error("Invalid initial date value:", field.value);
      }
    }
  }, [field.value]);

  // console.log(minDate,"minDate")
  return (
    <>
      <div className="form-group">
        {label &&    <label className="fltr-lable">
                  {label}
                </label>}
          <DatePicker
            style={customStyles}
            selected={date}
            minDate={minDate ? minDate : currentDate}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            dateFormat="MMM d, yyyy h:mm aa"
            timeCaption="Time"
            onChange={(selectedDate) => handleChangeDate(selectedDate)}
            placeholderText="Select Date and Time"
            className="form-control w-100"
            maxDate={maxDate}
            // filterDate={(date) => {
            //   if(minDate){
            //     return date.getTime() !== selectedDate?.getTime() && date > minDate;
            //   }else {

            //   const currentDate = new Date();
            //   return date.getTime() !== selectedDate?.getTime() && date > currentDate;
            // }

            // }} 
          />

          {errorType?.map((type) => {
            if (error?.type === type && error?.message !== "") {
              return (
                <span key={type} className={"error d-flex align-center"}>
                  {error?.message}
                </span>
              );
            }
          })}
        </div>
    </>
  );
}

export default DateTimePickerComponent;
import axios from "axios";
import {BASE_URL} from "../constants";

export const USER_LISTING = 'USER_LISTING';
export const DELETE_USER = 'DELETE_USER';
export const USER_STATUS = 'USER_STATUS';

export const getUserListing = (props) => {
    console.log('******', props);
    return axios({
        method: 'GET',
        params: props,
        url: `/user/list`
    });
};
export function getUserById(id){
    return axios({
        method: 'GET',
        // params: payload,
        url: '/user/'+id,
    })
}
export const addStaff = (props) => {
    return axios({
        method: 'POST',
        data: props,
        url: '/staff'
    });
};
export function editStaff(payload){
    return axios({
        method: 'PATCH',
        data: payload,
        url: '/staff'
    })
}
export function deleteUser(payload){
    return axios({
        method: 'DELETE',
        data: payload,
        url: '/staff'
    })
}

export function updateUserStatus(payload, id){
    // console.log('-----', id);
    return axios({
        method: 'PATCH',
        data: payload,
        url: '/user/status'
    })
}

export function verifyDocuments(payload){
    return axios({
        method: 'PATCH',
        data: payload,
        url: '/document/action'
    })
}

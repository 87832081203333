import { Link } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { deleteEmailRecord, updateEmailRecordStatus, DELETE_EMAIL_RECORD, EMAIL_RECORD_STATUS } from "../../actions/emailRecord";
import { useEffect, useState } from "react";
import {_} from "../../locale";
import { useDispatch } from "react-redux";
import moment from 'moment';

function ManageScheduleCampaignSlide({slideData, id, index, srno, emailListId}) {

  //   local variables
  const [status, setStatus] = useState(slideData?.status);
  const dispatch = useDispatch();

  const deleteRecord = () => {
    // confirmAlert({
    //   title: 'Confirm',
    //   message: _('CONFIRM_DELETE'),
    //   buttons: [
    //     {
    //       label: _('YES'),
    //       onClick: async () => {
    //         try {
    //           await deleteEmailRecord(slideData?.id);
    //           dispatch({ type: DELETE_EMAIL_RECORD, id: slideData?.id });
    //           // toast.info("Category type has been deleted successfully");
    //         } catch (error) {
    //           console.log(error);
    //         }
    //       }
    //     },
    //     {
    //       label: _('NO')
    //     }
    //   ]
    // });
  };


  const updateStatus = () => {
    // let status = (slideData.status == '1' ? 0 : 1);
    // let chkStatus = (slideData.status == '1' ? 0 : 1);
    // confirmAlert({
    //   title: 'Confirm',
    //   message: slideData.status == '1' ? _('CONFIRM_DEACTIVATE') : _('CONFIRM_ACTIVATE'),
    //   buttons: [
    //     {
    //       label: _('YES'),
    //       onClick: async () => {
    //         try {
    //           const response = await updateEmailRecordStatus({status} ,slideData?.id);
    //           dispatch({ type: EMAIL_RECORD_STATUS, payload: response?.data?.responseData });
    //         } catch (error) {
    //           console.log(error);
    //         }
    //       }
    //     },
    //     {
    //       label: _('NO')
    //     }
    //   ]
    // });
  };

  useEffect(() => {
    setStatus(slideData.status)
  }, [slideData.status])

  console.log(slideData, "SlideQuestion")

  let totalEmailRecords = 0;

  slideData?.CampaignScheduleEmailLists?.length > 0 && slideData?.CampaignScheduleEmailLists?.map((obj, index) => {
    totalEmailRecords += obj?.EmailList?.totalRecords ?? 0
  })


  return (
    <tr>
      <td>{parseInt(srno + parseInt(index + 1))}</td>
      <td><p className="font-size-14 mb-1">{slideData?.fromEmail ?? null}</p></td>
      <td><p className="font-size-14 mb-1">{moment(slideData?.sendAt).format('lll') ?? '-'}</p></td>
      <td><p className="font-size-14 mb-1">{moment(slideData?.sentOn).format('lll') ?? '-'}</p></td>

      <td><p className="font-size-14 mb-1">{totalEmailRecords}</p></td>
      
      <td>
        <h5 class="font-size-14 mb-1"><a href="javascript: void(0);" class="text-dark">{moment(slideData?.createdAt).format('lll') ?? null}</a></h5>
        <p class="text-muted mb-0">{_('BY')} {slideData?.author?.name ?? null}</p>
      </td>
      <td>
        <h5 class="font-size-14 mb-1"><a href="javascript: void(0);" class="text-dark">{slideData?.updatedAt ? moment(slideData?.updatedAt).format('lll') : moment(slideData?.createdAt).format('ll')}</a></h5>
        <p class="text-muted mb-0">{_('BY')} {slideData?.updatedBy?.name ? slideData?.updatedBy?.name : slideData?.author?.name }</p>
      </td>
      <td>
        <a href="javascript:void(0)" onClick={updateStatus}>
        {
          status
          ?
          <span className="badge bg-success">{_('ACTIVE')}</span>
          :
          <span className="badge bg-danger">{_('NOT_ACTIVE')}</span>  
        }
        </a>
      </td>
      <td>
        <ul className="list-unstyled hstack gap-1 mb-0">
          <li>
              <Link to={`/dashboard/email-campaign/${id}/schedule/${slideData?.id}/edit`} className="btn btn-sm btn-soft-info" title="Edit"><i className="mdi mdi-pencil-outline"></i></Link>
          </li>
          <li>
            <a href="javascript:void(0)" onClick={deleteRecord} className="btn btn-sm btn-soft-danger" title="Delete"><i className="mdi mdi-delete-outline"></i></a>
          </li>
        </ul>
      </td>
  </tr>
  );
}

export default ManageScheduleCampaignSlide;

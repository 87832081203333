import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Inputfield from '../FormFields/InputField';
import { useForm } from 'react-hook-form';
import {_} from '../../locale';
import { resetPassword } from '../../actions/auth';
import { toast } from 'react-hot-toast';

const ResetPassword = () => {

    // use Hooks
  const {
    control,
    handleSubmit,
    getValues,
    formState: { isSubmitting },
  } = useForm();
  
  const [getPassword, setGetPassword] = useState(true);
  const [getConfirmPassword, setConfirmPassword] = useState(true);
  const params = useParams();
  const navigate = useNavigate();
  console.log(params, "Param")
    //global variables
    const { id } = useParams();

    //local variables
  const [loading, setloading] = useState(false);

  const showPassword = () => {
    setGetPassword(!getPassword);
  };

  const showConfirmPassword = () => {
    setConfirmPassword(!getConfirmPassword);
  };
  const onSubmit = async(formValues) => {
    try {
        console.log(id,"idpp")
        if(id) {

            const resp = await resetPassword(id,{...formValues});
            console.log('passwordfor', resp);
            toast.success(resp?.data?.message);
            navigate("/")
        }

    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.non_field_errors);
      } else if (request) {
        toast.error(_('NO_INTERNET'));
      }
    }
  };

  return (
    <div className="forgot-password">
        <div className="forgot-password-inner">
          <div className="form-step-center">
            <h3 className="h3 text-grey-8 fw700 form-title">
              Reset Your Password
            </h3>
            <p className="p fs-14 text-grey-6">
              No worries, You will receive an email with instruction to reset
              your password.
            </p>
          </div>

          <form className="form-wrpr" onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-3">
          <label className="form-label">{_('PASSWORD')}</label>
          <div className="input-group auth-pass-inputgroup">
            <Inputfield
              control={control}
              name={"password"}
              label={_('CREATE_PASSWORD')}
              placeholder={_('CREATE_PASSWORD')}
              normalize={(e) => e.replace(/^\s+/g, "")}
              type={getPassword == true ? "password" : "input"}
              inputClassName={"form-control"}
              rules={{
                required: { value: true, message: _('PASSWORD_REQUIRED') },
                minLength: {
                  value: 4,
                  message: _('PASSWORD_VALIDATE'),
                },
              }}
            />
            <button className="btn btn-light " type="button" id="password-addon" onClick={showPassword}><i className="mdi mdi-eye-outline"></i></button>
           </div>
           <div className="mt-4">
          <label className="form-label">{_('CONFIRM_PASS')}</label>
           <div className="input-group auth-pass-inputgroup ">
            <Inputfield
              control={control}
              name={"confirm_password"}
              label={_('CONFIRM_PASS')}
              placeholder={_('CONFIRM_PASS')}
              normalize={(e) => e.replace(/^\s+/g, "")}
              type={getConfirmPassword == true ? "password" : "input"}
              inputClassName={"form-control"}
              rules={{
                required: { value: true, message: _('PASSWORD_REQUIRED') },
                minLength: {
                  value: 4,
                  message: _('PASSWORD_VALIDATE'),
                },
                validate: (value) =>
                  value === getValues("password") ||
                  "Password and Confirm password do not match",
              }}
            />
            <button className="btn btn-light " type="button" id="password-addon" onClick={showConfirmPassword}><i className="mdi mdi-eye-outline"></i></button>
           </div>
           </div>
        </div>
           
        <div className="mt-3 d-grid">
          <button
            type="submit"
            disabled={isSubmitting}
            className={`btn btn-primary waves-effect waves-light btn-bg ${
              isSubmitting ? "btn-loader" : ''
            }`}
          >
            {_('SEND')}
          </button>
        </div>
          </form>
          <p className="text-center d-flex align-items-center justify-content-center mt-4">
            <a href="/" className="gray-link fs-14">
              Back to Login
            </a>
          </p>
        </div>
      </div>
  )
}

export default ResetPassword
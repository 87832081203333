import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { _ } from "../../locale";

function LeftNavigation() {
  const [managePanel, setManagePanel] = useState(false);
  const [menuisActive, setmenuisActive] = useState(false);

  const handleToggle = () => {
    setmenuisActive(!menuisActive);
    setIsToggled(!isToggled);
  };
  const [isToggled, setIsToggled] = useState(false);
  useEffect(() => {
    const toggleClass = () => {
      if (isToggled) {
        document.body.classList.add("toggle-sidebar-open");
      } else {
        document.body.classList.remove("toggle-sidebar-open");
      }
    };
    toggleClass();
    return () => {
      document.body.classList.remove("toggle-sidebar-open");
    };
  }, [isToggled]);
  
  
  // handle management tab
  const handleManagement = () => {
    setManagePanel((prev) => !prev);
  };
  
  // handling route activeness
  const isActive = (match, loc, followPath) => {
    if (match) {
      return true;
    } else {
      let path = loc?.pathname?.split("/");
      if (followPath === path?.[2]) {
        return true;
      }
    }
  };


  return (
    <>
    
      <div className="vertical-menu">
        <div data-simplebar className="h-100">
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "waves-effect active" : "waves-effect"
                  }
                  to="/dashboard/analytics"
                >
                  <i className="bx bx-home-circle"></i>
                  <span key="t-dashboards">{_("DASHBOARD")} </span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "waves-effect active" : "waves-effect"
                  }
                  to="/dashboard/email-list"
                >
                  <i className="bx bx-store"></i>
                  <span key="t-dashboards">{_("EMAIL_LIST")} </span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "waves-effect active" : "waves-effect"
                  }
                  to="/dashboard/email-template"
                >
                  <i className="bx bx-list-ul"></i>
                  <span key="t-dashboards">{_("EMAIL_TEMPLATE")} </span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "waves-effect active" : "waves-effect"
                  }
                  to="/dashboard/email-automation"
                >
                  <i className="bx bx-briefcase-alt"></i>
                  <span key="t-dashboards">{_("EMAIL_AUTOMATION")} </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "waves-effect active" : "waves-effect"
                  }
                  to="/dashboard/email-campaign"
                >
                  <i className="bx bx-tone"></i>
                  <span key="t-dashboards">{_("EMAIL_CAMPAIGN")} </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "waves-effect active" : "waves-effect"
                  }
                  to="/dashboard/api-key"
                >
                  <i className="bx dripicons-gear"></i>
                  <span key="t-dashboards">{_("API_KEY")} </span>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className={`menus-bar ${menuisActive ? 'active' : ''}`} onClick={handleToggle}>
        <span class="bar"></span>
        <span class="bar"></span>
        <span class="bar"></span>
      </div>
    </>
  );
}

export default LeftNavigation;

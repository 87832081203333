import { EMAIL_AUTOMATION, DELETE_EMAIL_AUTOMATION, EMAIL_AUTOMATION_STATUS, SCHEDULE_EMAIL_AUTOMATION, DELETE_AUTOMATION_SCHEDULE} from "../actions/emailAutomation";

const initialState = {
  emailAutomationListing: null,
  scheduleEmailAutomationListing: null,
};

const emailAutomation = (state = initialState, action) => {

  switch (action.type) {
    case EMAIL_AUTOMATION:
      return {
        ...state,
        emailAutomationListing: action.payload
      };
    break;
    case DELETE_EMAIL_AUTOMATION:
      const data = { ...state.emailAutomationListing };
      let index = data?.data?.findIndex((item) => item.id === action.id);
      data?.data?.splice(index, 1);
      return {
        ...state,
        emailAutomationListing: data,
      };
    break;
    case EMAIL_AUTOMATION_STATUS:
      let automation = { ...state.emailAutomationListing };
      let endex = automation?.data?.findIndex((item) => item.id === action?.payload?.id);
      automation.data[endex] = {...automation.data[endex], ...action.payload};
      return {
        ...state,
        emailAutomationListing: {...automation},
      };
    break;

    case SCHEDULE_EMAIL_AUTOMATION:
      return {
        ...state,
        scheduleEmailAutomationListing: action.payload
      };
    break;

    case DELETE_AUTOMATION_SCHEDULE:
      const rec = { ...state.scheduleEmailAutomationListing };
      let andex = rec?.data?.findIndex((item) => item.id === action.id);
      rec?.data?.splice(andex, 1);
      return {
        ...state,
        scheduleEmailAutomationListing: rec,
      };
    break;

    default:
  }
  return state;
}
export default emailAutomation;
import React from "react";
import { render } from 'react-dom'
import {_} from "../../locale";
// import Highcharts from 'highcharts'
// import HighchartsReact from 'highcharts-react-official';
// import Highcharts3d from "highcharts/highcharts-3d.js";
// import Cylinder from "highcharts/modules/cylinder.js";
// import Funnel from "highcharts/modules/funnel3d";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Highcharts3D from 'highcharts/highcharts-3d';

// Highcharts3D(Highcharts);

function EmailStatsPieChart({detail}) {

    let records = [
        {name: _('SUBSCRIBERS'), y: detail?.emailListStats?.totalSubscribers},
        {name: _('UNSUBSCRIBERS'), y: detail?.emailListStats?.totalUnubscribers}
    ];

    const options = {
        chart: {
          type: 'pie',
          options3d: {
            enabled: true,
            alpha: 45,
            beta: 0
          }
        },
        title: {
          text: _('SUBSCRIPTION_STATS')
        },
        
        plotOptions: {
          pie: {
            innerSize: 100,
            depth: 45
          }
        },
        series: [{
          name: _('SUBSCRIPTION_STATS'),
          data: records
        }]
    };



  return (
    <div className="inner-content-ui order-analyitcs email-tab-stats">
      <div className="order-chart-block" style={{width:"100%"}}>
          <HighchartsReact highcharts={Highcharts} options={options}  style={{width:"100%"}} />
      </div>
    </div>

  );
}

export default EmailStatsPieChart;
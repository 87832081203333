import axios from "axios";
import {BASE_URL} from "../constants";

export const EMAIL_LIST = 'EMAIL_LIST';
export const DELETE_EMAIL_LIST = 'DELETE_EMAIL_LIST';
export const EMAIL_LIST_STATUS = 'EMAIL_LIST_STATUS';

export const getEmailList = (props) => {
    return axios({
        method: 'GET',
        params: props,
        url: `/emailList/list`
    });
};
export function getEmailListById(id){
    return axios({
        method: 'GET',
        // params: payload,
        url: '/emailList/id/'+id,
    })
}
export const addEmailList = (props) => {
    return axios({
        method: 'POST',
        data: props,
        url: '/emailList'
    });
};
export function editEmailList(payload, id){
    console.log('-----', id);
    return axios({
        method: 'PATCH',
        data: payload,
        url: '/emailList/'+id
    })
}
export function deleteEmailList(id){
    return axios({
        method: 'DELETE',
        url: '/emailList/'+id
    })
}

export function updateEmailListStatus(payload, id){
    // console.log('-----', id);
    return axios({
        method: 'PATCH',
        data: payload,
        url: '/emailList/status/'+id
    })
}
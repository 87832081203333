import React, { useEffect } from "react"
import { Field, ErrorMessage } from "formik"
import { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { removeFile, uploadFile } from "../../actions/auth";
 
function FileUpload(props) {
    const defaultFileTypes = ["JPEG", "PNG", "GIF", "JPG"];
    
    const { label, name, fileTypes, isMultiple, preUploadedFiles, preUploadedFileIds, ...rest } = props;
    // console.log('preUploadedFiles', preUploadedFiles);
    const [filesToBeUploaded, setFilesToBeUploaded] = useState(0);
    const [selectedFiles, setSelectedFiles] = useState(preUploadedFiles);
    const [filesUploaded, setFilesUploaded] = useState(0);
    const [file, setFile] = useState(null);
    const [fileIds, setFileIds] = useState(preUploadedFileIds ?? []);


    // console.log(selectedFiles, '0000000', fileIds);


    useEffect(() => {
        console.log(preUploadedFileIds, 'fffff6666')
        setFileIds(preUploadedFileIds);
        setSelectedFiles(preUploadedFiles);
    }, [preUploadedFileIds, preUploadedFiles])
    
    const handleChange = (fls, setFieldValue) => {
        let files;
        if(isMultiple){
            files = fls;
        }else{
            files = [fls];
        }
        setFilesToBeUploaded(filesToBeUploaded + 1);
        console.log(fileIds,'fffff');
        for (var i = 0, l = files.length; i < l; i++) {
            console.log(files[i].name);
            let newForm = new FormData();
            newForm.append("file", files[i]);
            uploadFile(newForm).then((response) => {
                //let ids = [...fileIds]; 
                setFilesToBeUploaded(filesToBeUploaded + 1);
                console.log(response?.data?.responseData?.id)
                //ids.push(response?.data?.responseData?.id);
                setSelectedFiles((prev) => {
                    let uploadedFiles;
                    if(isMultiple){
                        uploadedFiles = [...prev, response?.data?.responseData];
                    }else{
                        uploadedFiles = [response?.data?.responseData];
                    }
                    
                    return uploadedFiles;
                })
                setFileIds((prev) => {
                    console.log(prev, 'ppppppev');
                    let ids;
                    if(isMultiple){
                        if(prev && prev.length > 0){
                            ids = [...prev, response?.data?.responseData?.id];
                        }else{
                            ids = [response?.data?.responseData?.id];
                        }
                        
                        
                        
                    }else{
                        ids = response?.data?.responseData?.id;
                    }
                    console.log(ids, 'iiids')
                    setFieldValue(name, ids);
                    return ids;
                })
                // setFileIds(ids);



            }).catch((err) => {
                console.log(err)
                setFilesToBeUploaded(filesToBeUploaded + 1);
            });
        }
    };

    
    return (
        <div>
            <label htmlFor={name}>{label}</label>
            <Field
                
                name={name}
                render={({ field, form }) => {
                    return (
                        <>
                            <FileUploader
                                multiple={isMultiple ? true : false}
                                handleChange={(e) => handleChange(e, form.setFieldValue)}
                                name="file"
                                types={fileTypes ? fileTypes : defaultFileTypes}
                            />
                            {
                                <ul className="show-file-margin">
                                    {
                                        selectedFiles && selectedFiles?.length 
                                        ?
                                        selectedFiles?.map((obj, index) => {
                                            return (
                                                <li key={index}><img src = {obj?.filePath} style={{height: "80px", width: "100px"}} /></li>
                                            )
                                        })
                                        :
                                        <></>
                                    }
                                </ul>
                            }
                        </>
                    )
                }}
            />
            <span className="error-msg"><ErrorMessage name={name} /></span>
        </div>
    )
}
export default FileUpload;